//@flow

import type {CompanyAPI, PublicAPI, UserAPI} from "../api";

/**
 * Interfejs dla klasy, która obsługuje dodatkowe zdarzenia dla zapytań zdalnych.
 */
export interface ProxyCallbacks {
    processRedirect(path: string): void;
}

/**
 * Funkcja opakowująca w proxy proste RPC dla wywołania method na serwerze,
 * które zawsze zwracają Promise.
 * @param path baza ścieżki do zapytań
 * @return {T}
 */
export function remoteProxy<T>(path: string, callback?: ProxyCallbacks): T {
    return new Proxy({}, {
        generated: new Map(),
        get: function(target, name) {
            let res=this.generated.get(name);
            if(res) return res;
            res=function(...args) {
                return new Promise(((resolve, reject) => {
                    fetch(path+"/"+name, {
                        method: 'POST',
                        cache: 'no-cache',
                        credentials: 'same-origin',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(args)
                    }).then(res => {
                        res.json().then((res) => {
                            if(res && typeof(res)==='object') {
                                if(typeof(res.__redirect__)==='string') {
                                    if(callback) callback.processRedirect(res.__redirect__);
                                    return;
                                }
                            }
                            resolve(res);
                        }).catch(reject);
                    }).catch(reject);
                }))
            }
            this.generated.set(name, res);
            return res;
        }
    });
}

export const publicApi: PublicAPI = remoteProxy("/publicapi");
export const userApi: UserAPI = remoteProxy("/userapi");
export const companyApi: CompanyAPI = remoteProxy("/companyapi");