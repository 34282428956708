//@flow
import React from 'react';

import {Head, useRouteData} from "react-static";
import type {NewsInfo} from "../static-api";
import {Container} from "react-bootstrap";
import {useHistory} from "react-router-dom";

/**
 * Lista aktualności.
 */
export default () => {
    const data = useRouteData<{ news: Array<NewsInfo> }>();
    const history=useHistory();

    return <>
        <Head>
            <title>Aktualności</title>
        </Head>
        <Container className="p-section">
            <h1 className="text-center">Aktualności</h1>
            {data.news.map((n) => {
                return <div className="news-item pt-3" key={n.name}>
                    {n.date}
                    <h2><a
                        href={"/aktualnosci/"+n.name}
                        onClick={(e) => {
                            e.preventDefault(); e.stopPropagation();
                            history.push({
                                pathname: "/aktualnosci/"+n.name
                            });
                        }}
                    >{n.title}</a></h2>
                    <div className="news-summary" dangerouslySetInnerHTML={{ __html: n.summary }}/>
                </div>
            })}
        </Container>
    </>
}