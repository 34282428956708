//@flow
import {useRecoilValueLoadable} from "recoil";
import {userAtom} from "./UserSession";
import {useEffect} from "react";

/** Kod Google Analytics */
// export const GA_CODE="G-8RR8VMKDCG";

export function initGa(pageView?: boolean) {
}

/**
 * Hook do obsługi GoogleAnalytics, który podpina się pod użytkownika i położenie strony.
 */
export function useGoogleAnalytics() {
    const { contents, state }=useRecoilValueLoadable(userAtom);
    // const pathName=typeof(window)==='undefined'?"":(window.location.pathname+window.location.search);

    useEffect(() => {
        if(typeof(window)!=='undefined') {
            if (contents) {
                window.gtag({userId: contents.id});
            } else {
                window.gtag({userId: null});
            }
        }
    }, [ contents])
    // useEffect(() => {
    //     if(typeof(window)!=='undefined') {
    //         ReactGA.pageview(pathName);
    //     }
    // }, [ pathName ]);
}