//@flow
import React, {Ref, useCallback} from 'react';
import {Button, Col, Container, Dropdown, Navbar, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import type {UserInfo} from "../api";
import type {LanguageData} from "../LangType";
import {formatDate} from "./DateTime";
import {Icon} from "./Icon";
import {isUE, ueEvent} from "./UserCom";

export const isDevelopment=(!process.env.NODE_ENV || process.env.NODE_ENV === 'development');

export const DefaultLink=({ to, children, event, onClick, ...props }) => {
    const ueCallback=useCallback((e) => {
        if(!event || !isUE()) return;
        e.preventDefault();
        ueEvent(event).finally(() => {
            if(onClick) onClick(e);
            window.location.href=to;
        });
    }, [ to, event ])
    return <a href={to} onClick={ueCallback} {...props}>{children}</a>
};

export const Footer=({ linkComponent }: {
    linkComponent?: React$Node
}) => {
    const Link=linkComponent?linkComponent:DefaultLink;

    return <footer id="page-footer" className="footer bg-white">
        <Container fluid="xl" className="pb-3 pt-5">
            <Row>
                <Col lg={3} className="text-center pb-3 pt-3">
                    <img
                        src="/images/logo.svg"
                        alt="Archiplaner"
                        style={{height: '5em'}}
                        width={106} height={50}
                    />
                </Col>
                <Col lg={9}>
                    <Row className="d-print-none">
                        <Col md={6}>
                            <Row>
                                <Col sm={6}>
                                    <ul>
                                        <li>Archiplaner.pl</li>
                                        <li>ul. Wita Stwosza 16</li>
                                        <li>50-148 Wrocław</li>
                                    </ul>
                                </Col>
                                <Col sm={6}>
                                    <ul>
                                        <li><Link data-prefetch="false" to="/draw/" event="archi_click_wybrobuj_footer">Wypróbuj za darmo</Link></li>
                                        <li><Link data-prefetch="false" to="/app/" event="archi_click_zaloguj_footer">Zaloguj się</Link></li>
                                        <li><Link to="/poradnik" event="archi_click_poradnik_footer">Poradnik</Link></li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <Row>
                                <Col sm={6}>
                                    <ul>
                                        <li><Link to="/rzuty-mieszkan" event="archi_click_rzuty_mieszkan">Rzuty mieszkań</Link></li>
                                        {/*<li><Link to="/rzuty-mieszkan">Rzuty domów</Link></li>*/}
                                    </ul>
                                </Col>
                                <Col sm={6}>
                                    <ul>
                                        <li><Link to="/regulamin" event="archi_click_regulamin">Regulamin</Link></li>
                                        <li><Link to="/polityka-prywatnosci" event="archi_click_polityka_prywatnosci">Polityka prywatności</Link></li>
                                        <li><a href="mailto:info@archiplaner.pl">info@archiplaner.pl</a></li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <hr/>
            <div className="text-center">
                © 2020 Archiplaner.pl Wszelkie prawa zastrzeżone.
            </div>
        </Container>
    </footer>;
};

/*
 * Plik z komponentami używanymi do zbudowania układu strony, w szczególności menu górnego.
 */

export const Header = ({ children, innerRef, drawMode, border, onClick }: {
    innerRef?: Ref;
    drawMode?: boolean;
    border?: boolean;
    onClick?: (e: SyntheticEvent) => void;
}) => {

    return <Navbar
        as="header" bg={drawMode===true?null:"white"}
        expand="lg" className={"header"+(drawMode===true?" navbar-border-bottom":"")+(border===true?" navbar-border-bottom2 ":"")}
        sticky={drawMode===true?null:"top"}
        ref={innerRef}
        id="header"
    >
        <Container fluid="xl">
            <Navbar.Brand href="/" onClick={onClick}>
                <img src="/images/logo.svg"
                     alt="Archiplaner"
                     width={106} height={50}
                     style={{ height: '2.5em' }}
                />
            </Navbar.Brand>
            {children}
        </Container>
    </Navbar>
};

const NoExportsTooltip = ({msgs, ...props}) => (
    <Tooltip id="hint-no-exports" {...props}>{msgs.commons.noExportsInfo}</Tooltip>
)

export const AccountInfo = ({ userInfo, link, prefix, msgs }: {
    userInfo: UserInfo,
    link?: (to: string) => void,
    prefix?: string;
    msgs: LanguageData;
}) => {
    if(!userInfo) return null;  // niezalogowany użytkownik
    if(userInfo.type==="CompanyWorker") return null;    // pracownik organizacji

    const linkFunc=typeof(link)==="function"?link:(to) => window.location.href="/app"+to;
    const linkPrefix=typeof(prefix)==='string'?prefix:"/app";
    if(userInfo.type==="Free") {    // konto darmowe
        if(userInfo.exports===0) {
            return <div>
                {/*<OverlayTrigger*/}
                {/*    placement="bottom"*/}
                {/*    overlay={<NoExportsTooltip msgs={msgs}/>}*/}
                {/*>*/}
                    <a
                        href={linkPrefix+"/packet/free_10_exports"}
                        className="btn btn-link btn-link-animated"
                        onClick={(e) => linkFunc("/packet/free_10_exports", e)}
                    >
                        {msgs.commons.buttonBuyNow}
                    </a>
                {/*</OverlayTrigger>*/}
            </div>

        }
        return <div>
            <a
                href={linkPrefix+"/account"}
                className="btn btn-link btn-link-animated"
                onClick={(e) => linkFunc("/account", e)}
            >
                {msgs.commons.userMenuExports+": "+userInfo.exports}
            </a>
        </div>
    }
    // Konta płatne
    return <div>
        <a
            href={linkPrefix+"/account"}
            className="btn btn-link btn-link-animated"
            onClick={(e) => linkFunc("/account", e)}
        >
            {msgs.commons.userMenuValidity+": "+formatDate(userInfo.validity)}
        </a>
    </div>
};

export const UserMenu = ({ userInfo, link, prefix, msgs, children }: {
    userInfo: UserInfo;
    link?: (to: string) => void;
    prefix?: string;
    children?: any;
    msgs: LanguageData;
}) => {
    const linkFunc=typeof(link)==="function"?link:(to) => window.location.href="/app"+to;
    const linkPrefix=typeof(prefix)==='string'?prefix:"/app";

    return <>
        <Dropdown>
        <Button
            variant="outline-primary"
            aria-label={msgs.commons.userMenuLabel}
            onClick={(e) => linkFunc("/", e)}
        ><div className="button-icon"><Icon.Home/></div> {userInfo.name}</Button>
        <Dropdown.Toggle split variant="primary" id="user-menu"/>
        <Dropdown.Menu>
            {(userInfo.accounts && userInfo.accounts>0) && <Dropdown.Item
                href={linkPrefix+"/users"}
                onClick={(e) => linkFunc("/users", e)}
            >Konta użytkowników</Dropdown.Item>}
            {userInfo.type!=="Company" && <Dropdown.Item
                href={linkPrefix+"/"}
                onClick={(e) => linkFunc("/", e)}
            >{msgs.commons.userMenuSketches}</Dropdown.Item>}
            <Dropdown.Item
                href={linkPrefix+"/settings"}
                onClick={(e) => linkFunc("/settings", e)}
            >{msgs.commons.userMenuSettings}</Dropdown.Item>
            {(userInfo.type!=="CompanyWorker") && <Dropdown.Item
                href={linkPrefix+"/packet"}
                onClick={(e) => linkFunc("/packet", e)}
            >{msgs.commons.userMenuPacket}</Dropdown.Item>}
            <Dropdown.Item
                href={linkPrefix+"/account"}
                onClick={(e) => linkFunc("/account", e)}
            >{msgs.commons.userMenuHistory}</Dropdown.Item>
            <Dropdown.Item
                href={linkPrefix+"/password"}
                onClick={(e) => linkFunc("/password", e)}
            >{msgs.commons.userMenuPassword}</Dropdown.Item>
            {userInfo.admin && <Dropdown.Item
                href={"/admin"}
            >Panel administracyjny</Dropdown.Item>}
            {children}
        </Dropdown.Menu>
    </Dropdown>
    </>;
};

