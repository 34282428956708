//@flow
import React, {useCallback, useEffect, useRef, useState} from 'react'
import "./styles.scss"
import {addPrefetchExcludes, Head, Root, Routes, useLocation, useSiteData} from 'react-static';
import {Nav, Navbar} from "react-bootstrap";
import {Footer, Header, UserMenu} from "./lib/Layout";
import {Link, Route, Switch, useHistory} from 'react-router-dom'
import {LandingLink} from "./Components";
import {SiteDataMeta} from "./static-api";
import type {LanguageData} from "./LangType";
import {CookieInfo} from "./lib/CookieInfo";
import {MutableSnapshot, RecoilRoot, useRecoilValueLoadable} from "recoil";
import {userAtom} from "./lib/UserSession";
import {langAtom, langModulesAtom, msgsAtom} from "./lib/Language";
import {ueChat, uePageHit} from "./lib/UserCom";
import {initGa, useGoogleAnalytics} from "./lib/GoogleAnalytics";
import {useFacebookPixel} from "./lib/FacebookPixel";

// console.log("process.env.REACT_STATIC_DISABLE_ROUTE_PREFIXING", process.env.REACT_STATIC_DISABLE_ROUTE_PREFIXING);
// console.log("process.env.REACT_STATIC_SITE_ROOT", process.env.REACT_STATIC_SITE_ROOT);
// console.log("process.env.REACT_STATIC_PUBLIC_PATH", process.env.REACT_STATIC_PUBLIC_PATH);

// console.log("Path '/app'", isPrefetchableRoute("/app"));
// console.log("Path '/app/'", isPrefetchableRoute("/app/"));
// console.log("Path '/app/'", isPrefetchableRoute("/app/index.html"));
// console.log("Path '/draw'", isPrefetchableRoute("/draw"));
// console.log("Path 'https://dev.archiplaner.pl/draw/routeInfo.json", isPrefetchableRoute('https://dev.archiplaner.pl/draw/routeInfo.json'));

initGa();

const ScrollTop = () => {
    const [ visible, setVisible ] = useState(false);

    useEffect(() => {
        const listener=() => {
            const pos=window.scrollY;
            if(visible) setVisible(pos>30);
            else setVisible(pos>100);
        };
        document.addEventListener('scroll', listener);

        return () => document.removeEventListener('scroll', listener);
    }, [ visible ]);
    const scrollTop=useCallback(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);
    return <div
        id="scroll-top"
        className="btn-secondary"
        onClick={scrollTop}
        style={{ display: visible?"block":"none" }}
    >
        <span>&#187;</span>
    </div>
}

const Page= () => {
    const location = useLocation();
    const history=useHistory();
    const { lang }=useSiteData<SiteDataMeta>();
    const msgs:LanguageData=lang.pl;
    const { contents, state }=useRecoilValueLoadable(userAtom);
    const chat=useRef(false);

    let user=(state==='hasValue')?contents:null;
    const pathName = location ? (location.pathname+location.hash) : "";

    useGoogleAnalytics();   // Hook do obsługi GoogleAnalytics
    useFacebookPixel(); // Hook do obsługi Facebook Pixel
    // console.log("PathName: ", pathName, "HistoryLocation: ", history.location);
    useEffect(() => {
        if (typeof (window) !== 'undefined') {

            const page=history.location;
            const sessionKey="hs_"+page.key+"_"+window.location+(window.location.hash || "");
            const lastScroll=window.sessionStorage.getItem(sessionKey);
            // console.log("Enter: ", page, sessionKey, lastScroll);
            if(lastScroll) {
                window.setTimeout(() => window.scrollTo(0, JSON.parse(lastScroll)), 0);
            } else {
                if(window.location.hash && window.location.hash.length>1) {
                    let tr=0;
                    const hashFunc=() => {
                        const el=document.getElementById(window.location.hash.substr(1));
                        console.log("ScrollTo", window.location.hash, el)
                        if(!el && tr<2) {
                            tr++;
                            window.setTimeout(hashFunc, 5);
                            return;
                        }
                        if(el) {
                            // console.log("Scroll");
                            const header = document.getElementById("header");
                            const pos=el.offsetTop-header.offsetHeight;
                            window.scrollTo(0, pos);
                            window.sessionStorage.setItem(sessionKey, pos);
                        } else {
                            window.scrollTo(0, 0);
                            console.warn("No hash element");
                        }
                    };
                    window.setTimeout(hashFunc, 0);
                } else {
                    window.scrollTo(0, 0);
                }
            }

            return () => {
                window.sessionStorage.setItem(sessionKey, window.scrollY);
                // console.log("Leave: ", page, window.scrollY);
            }
        }
    }, [pathName, history.location ]);
    // Dla User.com
    useEffect(() => {
        if(typeof(window)!=='undefined') {
            const chatFunc=chat.current?undefined:() => ueChat();
            chat.current=true;
            if(contents) uePageHit({ user_id: contents.id, email: contents.email }).finally(chatFunc);
            else uePageHit({}).finally(chatFunc);
        }
    }, [contents, pathName]);

    return <>
        <Head titleTemplate="%s - Archiplaner.pl" defaultTitle="Archiplaner.pl"/>
        <Header onClick={(e) => {
            e.preventDefault();
            history.push("/");
        }}>
            <Navbar.Toggle aria-controls="main-menu"/>
            <Navbar.Collapse id="main-menu" className="justify-content-end">
                <Nav className="nav-spacing">
                    <Nav.Item>
                        <Link to="/#cennik" className="btn btn-link btn-link-animated">Cennik</Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Link to="/poradnik" className="btn btn-link btn-link-animated">Poradnik</Link>
                    </Nav.Item>
                    {user ? (<>
                        <Nav.Item>
                            <UserMenu msgs={msgs} userInfo={user}/>
                        </Nav.Item>
                    </>) : (<>
                        <Nav.Item>
                            <a href="/app/" className="btn btn-outline-primary">{msgs.commons.buttonLogin}</a>
                        </Nav.Item>
                        <Nav.Item>
                            <a href="/draw/" className="btn btn-primary">Wypróbuj za darmo</a>
                        </Nav.Item>
                    </>)}
                </Nav>
            </Navbar.Collapse>
        </Header>
        <div className="page-content">
            <Switch>
                <Route render={() => <Routes/>}/>
            </Switch>
        </div>
        <Footer linkComponent={LandingLink}/>
        <CookieInfo/>
        <ScrollTop/>
    </>
}

export const AppContext = () => {
    const { lang }=useSiteData<SiteDataMeta>();
    return <RecoilRoot initializeState={(s: MutableSnapshot)=> {
        s.set(langModulesAtom, "commons");
        s.set(langAtom, "pl");
        s.set(msgsAtom, lang.pl);
    }}>
        <Page/>
    </RecoilRoot>

}

export default () => {
    return (
        <Root>
            <React.Suspense fallback={null}>
                <AppContext/>
            </React.Suspense>
        </Root>
    )
}
