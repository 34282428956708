//@flow
import React from 'react';
import {Head, useSiteData} from "react-static";
import {Container, Row, Col} from "react-bootstrap";
import type {SiteDataMeta} from "../static-api";
import Images, {Image} from "../Images";

export default () => {
    const {siteRoot} = useSiteData<SiteDataMeta>();

    return <>
        <Head>
            <title>Rzuty mieszkań - jak je rysować</title>
            <link rel="canonical" href={siteRoot + "/rzuty-mieszkan"}/>
            <meta name="description"
                  content="Narysuj swój pierwszy plan mieszkania w programie do rzutów dostępnym online. Stwórz nowy rzut mieszkania, domu czy lokalu w Archiplaner."/>
        </Head>
        <Container className="help-content">
            <h1>Rzuty mieszkań - jak je rysować</h1>

            <div className="image-title">
                <span>Rzuty mieszkań - jak je rysować?</span>
                <Image src="/images/rzuty-mieszkan/1.zdjecie-glowne2.jpg"
                       className="mb-3"
                       title="Rzuty mieszkań - jak je rysować?"
                       alt="Jak rysować rzuty mieszkań?"/>
            </div>

            <p>Rzuty mieszkań to wartościowe źródła informacji, które są idealnym dodatkiem do zdjęć oglądanej
                nieruchomości. Co ważne rzuty
                mieszkań mogą ułatwić nam zaplanowanie nowej aranżacji naszego
                mieszkania.</p>

            <p>Dlatego w tym artykule przygotowaliśmy uzupełnienie informacji do procesu tworzenia rzutów mieszkań.
                Dzięki temu poradnikowi
                dowiesz się na co zwrócić uwagę podczas rysowania rzutów.
                Dowiesz się również jak narysować plan mieszkania w kilka minut - i pracować na wersji roboczej planu
                dowolnie go
                modyfikując.</p>

            <p>W tym poradniku poruszyliśmy również kwestie przydatności rzutów mieszkań oraz ich dostępne wersje, z
                którymi mamy styczność w
                różnych momentach przy pracy z nieruchomościami.</p>

            <div className="pb-4 pt-4">
                <div className="card" style={{display: "inline-block", paddingRight: "3em"}}>
                    <div className="card-body">
                        <h5 className="card-title">Spis treści</h5>
                        <ol>
                            <li><a href="#popularne-plany-mieszkan">Dlaczego plany mieszkań są tak popularne?</a></li>
                            <li><a href="#rzut-online">Dostęp do rzutu mieszkania online?</a></li>
                            <ol>
                                <li><a href="#jak-korzystac">Jak korzystać z programu do rzutów online</a></li>
                            </ol>
                            <li><a href="#zwymiarowany-rzut">Rzut mieszkania z wymiarami w Archiplaner</a>
                                <ol>
                                    <li><a href="#przyklady-rzutow">Przykłady rzutów mieszkań z wymiarami</a></li>
                                </ol>
                                <ol>
                                    <li><a href="#1-pok">Rzuty mieszkań 1 pokojowych</a></li>
                                    <li><a href="#2-pok">Rzuty mieszkań 2 pokojowych</a></li>
                                    <li><a href="#3-pok">Rzuty mieszkań 3 pokojowych</a></li>
                                    <li><a href="#4-pok">Rzuty mieszkań 4 pokojowych</a></li>
                                    <li><a href="#5-pok">Rzuty mieszkań 5 pokojowych</a></li>
                                    <li><a href="#rzuty-domow">Rzuty domów</a></li>
                                </ol>
                            </li>
                            <li><a href="#poziomy-rzut">Poziomy rzut mieszkania</a>
                                <ol>
                                    <li><a href="#poziome-rzuty">Poziome rzuty mieszkań w Archiplaner</a></li>
                                </ol>
                            </li>
                            <li><a href="#rzut-techniczny">Rzut techniczny mieszkania - kiedy jest potrzebny?</a>
                                <ol>
                                    <li><a href="#symbole-planu">Symbole na planie mieszkania</a></li>
                                    <li><a href="#kto-techniczny">Od kogo otrzymamy techniczny rzut mieszkania</a></li>
                                </ol>
                            </li>
                            <li><a href="#rzut-bloku">Rzut mieszkania w bloku</a></li>
                        </ol>
                    </div>
                </div>
            </div>

            <h2 className="anchor" id="popularne-plany-mieszkan">Dlaczego plany mieszkań są tak popularne?</h2>

            <p>Niezależnie od tego czy jesteśmy właścicielem nieruchomości czy osobą zainteresowaną zakupem mieszkania,
                w codziennym życiu mogą
                pojawić się sytuacje wymagające zmierzenia poszczególnych
                ścian i odległości sąsiadujących pomieszczeń.</p>

            <p>Spisanie długości na kartce może być bardzo proste. Natomiast co jeżeli zdecydujemy się przemeblować
                nieruchomość lub wprowadzić
                zmiany lokatorskie dotyczące przebudowy ścian czy przebudowy
                instalacji elektrycznej/wodnej-kanalizacyjnej?</p>

            <Images>
                <Row className="images">
                    <Col md={6} className="mx-auto">
                        <Image src="/images/rzuty-mieszkan/3.plany-mieszkan.png" alt="dwa podziały planu mieszkania"/>
                    </Col>
                </Row>
            </Images>

            <p>Pomocnym rozwiązaniem może być wprowadzenie informacji technicznych na <strong>przygotowany przez nas
                rzut mieszkania</strong>. Z
                jednej strony zyskujemy wizualne ułatwienie wprowadzonych
                przez nas zmian aranżacji. Z drugiej strony możemy zebrać wszystkie informacje techniczne i przepisać je
                na plan mieszkania.</p>

            <p>W tym artykule zwrócimy uwagę na <strong>techniczne i praktyczne zastosowanie rzutów mieszkań</strong> w
                naszym codziennym życiu.
            </p>

            <Images>
                <Row className="images">
                    <Col md={6} className="text-center">
                        <Image src="/images/rzuty-mieszkan/4.umeblowany-rzut.png" alt="umeblowany rzut mieszkania"/>
                    </Col>
                    <Col md={6} className="text-center">
                        <Image src="/images/rzuty-mieszkan/4.techniczny-rzut.png" alt="techniczny plan mieszkania"/>
                    </Col>
                </Row>
            </Images>

            <h2 className="anchor" id="rzut-online">Dostęp do rzutu mieszkania online?</h2>

            <p>Łatwy i szybki dostęp do <a href="/nowy-rzut-mieszkania-online/" target="blank">rysowania rzutu
                mieszkania w formie online</a> to przede wszystkim spora oszczędność czasu, który może być
                nam potrzebny w codziennym życiu.</p>

            <Image
                className="mb-3"
                src="/images/rzuty-mieszkan/5.dostep-do-rzutu-online.jpg"
                alt="dostęp do rzutu mieszkania online"/>

            <p>Pierwszym krokiem na drodze do oszczędności będzie praca z programem do rysowania planów, który jest
                dostępny w każdym miejscu
                gdzie mamy dostęp do internetu, ponieważ:</p>
            <ul>
                <li>możemy w każdym momencie wrócić do edycji i rysowania rzutu</li>
                <li>wystarczy, że zalogujemy się na nasze konto, niezależnie na jakim komputerze czy tablecie się
                    znajdujemy
                </li>
                <li>możemy nanosić zmiany na rzut w czasie rzeczywistym będąc na nieruchomości</li>
                <li>możemy tworzyć różne wersje rzutów kopiując je między sobą i skupiając się na różnych koncepcjach
                    aranżacji pomieszczeń
                </li>
            </ul>

            <p>Druga bardzo ważna kwestia to prosta i czytelna obsługa programu do rzutów zapewniająca nam szybkie
                tworzenie interesujących nas
                koncepcji aranżacji naszego mieszkania.</p>

            <p>Powinniśmy jednak pamiętać o tym, że <b>rysowanie rzutu mieszkania</b> online jest procesem, który składa
                się z kilku etapów.</p>

            <p>Podzielimy się teraz naszymi doświadczeniami, dzięki którym rysowanie rzutów będzie łatwiejsze.</p>

            <h3 className="anchor" id="jak-korzystac">Jak korzystać z programu do rzutów online</h3>

            <p>Skupiamy się na sprawdzonych sposobach dzięki, którym rysowanie nowych planów mieszkań zajmie zaledwie
                kilka minut.</p>

            <ul>
                <li><b>Dodanie podkładu do rzutu</b></li>
            </ul>

            <p>Pierwszym ułatwieniem na drodze do stworzenia rzutu mieszkania z wymiarami jest <a
                href="/program-do-rzutow/#dodanie-podkladu" target="blank">funkcja dodania podkładu do rzutu</a>, która
                została
                opisana szczegółowo w naszym poradniku.

                To pozwoli nam zaoszczędzić sporo czasu na odrysowanie pomieszczeń z zachowaniem proporcji.</p>

            <Image src="/images/rzuty-mieszkan/6.podklad-do-rzutu-mieszkania.png"
                   alt="rzut mieszkania dostępny online"
                   className="mb-3 d-block mx-auto"
            />

            <ul>
                <li><b>Najpierw ściany nośne, a później działowe</b></li>
            </ul>

            <p>Rysowanie rzutu powinniśmy zacząć od dodania ścian nośnych, a następnie ścian działowych. Taka kolejność
                pozwoli na szybsze
                przygotowanie rzutu z prawidłowym podziałem nieruchomości na
                poszczególne pomieszczenia. Zaczynając od poszczególnych ścian pokoi często poprawiamy obrys
                nieruchomości wraz z nośnymi
                ścianami znajdującymi się wewnątrz pokoi.</p>

            <Images>
                <Row className="images">
                    <Col md={6} className="text-center">
                        <Image src="/images/rzuty-mieszkan/7.cz1-sciany-nosne.png"
                               alt="dodanie ściany nośnej i działowej na rzut mieszkania"/>
                    </Col>
                    <Col md={6} className="text-center">
                        <Image src="/images/rzuty-mieszkan/7.cz2-sciany-nosne.png"
                               alt="zmiana grubości ściany nośnej i działowej na planie mieszkania"/>
                    </Col>
                </Row>

            </Images>

            <p>Co powoduje, że tracimy czas na poprawki i przesunięcia również ścian nośnych. Natomiast jeśli już raz
                poprawnie naniesiemy obrys
                nieruchomości to <a href="/nowy-rzut-mieszkania-online/#edycja-scian" target="blank">dodanie ścian
                    działowych</a> zajmie nam
                zdecydowanie mniej czasu.</p>

            <ul>
                <li><b>Skróty klawiszowe to podstawa</b></li>
            </ul>

            <p>Powinniśmy Korzystać z następujących <a href="/program-do-rzutow/#skroty-klawiszowe" target="blank">skrótów
                klawiszowych</a> podczas rysowaniu rzutu:</p>

            <ul>
                <li>dodanie ściany - <b>“spacja”</b>, dodanie drzwi -<b>”q”</b> i okien - <b>“w”</b> oraz zmiana
                    wielkości ścian - ściana
                    nośna <b>“1”</b>, ściana działowa <b>“2”</b>, ściana
                    balkonowa <b>“3”</b>, ściana ukryta <b>“4”</b></li>
            </ul>

            Podczas edycji rzutu powinniśmy korzystać z następujących skrótów klawiszowych:
            <ul>
                <li><b>“enter”</b> do przechodzenia do właściwości pomieszczenia, oraz <b>“delete”</b></li>
                <li><b>gotowe szablony rzutów</b></li>
            </ul>

            <p>Jeśli nie mamy przygotowanego wcześniej odręcznego podkładu rzutu mieszkania, który możemy wgrać do <a
                href="/draw/" target="blank">programu do rysowania planów</a>
                wówczas z pomocą nam przyjdą gotowe szablony rzutów.</p>

            <Images>
                <Row className="images">
                    <Col md={6} className="mx-auto">
                        <Image src="/images/rzuty-mieszkan/8.gotowe-szablony.png" alt="gotowe szablony rzutów mieszkań"/>
                    </Col>
                </Row>
            </Images>

            <p><b>Wybieramy w pierwszej kolejności</b> folder szablonów odpowiadający liczbie pokoi naszej
                nieruchomości. Następnie szukamy
                szablonu, który ma zbliżony układ pomieszczeń oraz metraż do
                naszego mieszkania.</p>

            <p>Wprowadzamy proste zmiany takie jak:</p>
            <ul>
                <li>przesunięcia ściana</li>
                <li>dodanie/usunięcie ścian</li>
                <li>dodanie/usunięcie okiem</li>
                <li>dodanie/usunięcie drzwi</li>
            </ul>

            <p>I praktycznie rzut mamy już gotowy. Pozostają tylko meble.</p>

            <h2 className="anchor" id="zwymiarowany-rzut">Rzut mieszkania z wymiarami w Archiplaner</h2>

            <p>Podczas przygotowywania zwymiarowanego rzutu mieszkania w pierwszej kolejności skoncentrujmy się na
                zachowaniu proporcji
                tworzonego rzutu następnie możemy skupić na dokładnych wymiarach
                poszczególnych ścian.</p>

            <Image src="/images/rzuty-mieszkan/9.obrys-nieruchomosci-najpierw.jpg"
                   alt="najpierw obrys planu mieszkania"
                   className="mb-3 d-block"
            />

            <p>Jeżeli podkład do rzutu mieszkania został przygotowany przez nas odręcznie na papierze, możemy mieć
                pewność, że nie odrysowujemy
                rzut 1:1.</p>

            <p>Próba skupiania się na wprowadzeniu dokładnych wymiarów ścian w aplikacji bez dokładnego technicznego
                rzutu przygotowanego przez
                architekta może spowodować, że stracimy dużo czasu na
                dopasowanie określonych proporcji pomieszczeń.</p>

            <h3 className="anchor" id="przyklady-rzutow">Przykłady rzutów mieszkań z wymiarami</h3>

            <p>W programie do rzutów archiplaner mamy dostęp do przygotowanej biblioteki planów mieszkań i domów, którą
                rozwijamy. Wystarczy, że
                wybierzesz interesujący Cię rodzaj nieruchomości i
                dostosujesz go do własnych potrzeb.</p>

            <p>Na podstawie naszego doświadczenia praca z gotowymi szablonami może zaoszczędzić Tobie dodatkowe
                kilkanaście minut na tworzenie
                planu mieszkania - <b>jeśli tworzysz rzut po raz pierwszy</b>.
            </p>

            <p>Dlatego systematycznie rozwiajamy bazę dostępnych gotowych planów mieszkań do Twojej dalszej edycji.</p>

            <h4 className="anchor" id="1-pok">Rzuty mieszkań 1 pokojowych</h4>

            <p>Kawalerki są jednym z bardziej pożądanych produktów mieszkań dostępnych na rynku. Jest to spowodowane
                przede wszystkim
                zapotrzebowaniem na mieszkania jednopokojowe.Idealnie nadają się jako
                inwestycje pod wynajem krótko i długoterminowy.</p>

            <p>Dlatego <b>rzuty kawalerek</b> są przydatnymi źródłami informacji jeśli chodzi o dostępne możliwości
                aranżacji małego 1
                pokojowego mieszkania.</p>

            <Images>
                <Row className="images">
                    <Col md={6}>
                        <Image src="/images/rzuty-mieszkan/1-pok/1.1-pok-26-m2.png"
                               alt="1 pokojowy rzut mieszkania 26 metrów"/>
                    </Col>
                    <Col md={6}>
                        <Image src="/images/rzuty-mieszkan/1-pok/2.1-26_5-m2.png"
                               alt="1 pokojowy rzut mieszkania 25,5 metrów"/>
                    </Col>
                </Row>
                <Row className="images">
                    <Col md={6}>
                        <Image src="/images/rzuty-mieszkan/1-pok/3.1-pok-27_6-m2.png"
                               alt="1 pokojowy rzut mieszkania 27 metrów"/>
                    </Col>
                    <Col md={6}>
                        <Image src="/images/rzuty-mieszkan/1-pok/4.1-pok-34-m2.png"
                               alt="1 pokojowy rzut mieszkania 34 metry"/>
                    </Col>
                </Row>
            </Images>

            <h4 className="anchor" id="2-pok">Rzuty mieszkań 2 pokojowych</h4>

            <p>Mieszkania dwupokojowe są również pożądanym produktem nieruchomości. Z jednej strony nadają się pod
                inwestycję, a z drugiej
                strony dwa niezależne pokoje zaspokajają potrzeby większości
                nowych par bez dzieci.</p>

            <p>W programie archiplaner przygotowaliśmy kilka dostępnych wariantów podziału sypialni i salonu.
                Uwzględniliśmy również salon z
                aneksem kuchennym oraz rzut mieszkania 2 pokojowego z oddzielną
                kuchnią.</p>

            <Images>
                <Row className="images">
                    <Col md={6}>
                        <Image src="/images/rzuty-mieszkan/2-pok/1.2-pok-29-m2.png"
                               alt="2 pokojowy rzut mieszkania 29 metry"/>
                    </Col>
                    <Col md={6}>
                        <Image src="/images/rzuty-mieszkan/2-pok/2.2-pok-33-m2.png"
                               alt="2 pokojowy rzut mieszkania 33 metry"/>
                    </Col>
                </Row>
                <Row className="images">
                    <Col md={6}>
                        <Image src="/images/rzuty-mieszkan/2-pok/3.2-pok-40-m2.png"
                               alt="2 pokojowy rzut mieszkania 40 metrów"/>
                    </Col>
                    <Col md={6}>
                        <Image src="/images/rzuty-mieszkan/2-pok/4.2-pok-42-m2.png"
                               alt="1 pokojowy rzut mieszkania 42 metry"/>
                    </Col>
                </Row>
            </Images>

            <h4 className="anchor" id="3-pok">Rzuty mieszkań 3 pokojowych</h4>

            <p>Rzuty 3 pokojowych mieszkań bywają już bardziej wymagające niż mniejsze lokale mieszkalne. Co powoduje,
                że wraz ze wzrostem
                powierzchni mieszkalnej, mamy więcej możliwości aranżacji całej
                nieruchomości. Przygotowaliśmy kilka popularnych wariantów 3 pokojowych rzutów mieszkań.</p>

            <p>Więcej rzutów znajdziesz bezpośrednio w aplikacji do rysowania:</p>

            <Images>
                <Row className="images">
                    <Col md={6}>
                        <Image src="/images/rzuty-mieszkan/3-pok/1.3-pok-55-m2.png"
                               alt="3 pokojowy rzut mieszkania 55 metrów"/>
                    </Col>
                    <Col md={6}>
                        <Image src="/images/rzuty-mieszkan/3-pok/2.3-pok-59-m2.png"
                               alt="3 pokojowy rzut mieszkania 59 metrów"/>
                    </Col>
                </Row>
                <Row className="images">
                    <Col md={6}>
                        <Image src="/images/rzuty-mieszkan/3-pok/3.3-pok-69-m2.png"
                               alt="3 pokojowy rzut mieszkania 69 metrów"/>
                    </Col>
                    <Col md={6}>
                        <Image src="/images/rzuty-mieszkan/3-pok/4.3-pok-70-m2.png"
                               alt="3 pokojowy rzut mieszkania 70 metrów"/>
                    </Col>
                </Row>
            </Images>

            <h4 className="anchor" id="4-pok">Rzuty mieszkań 4 pokojowych</h4>

            <p>Zazwyczaj 4 pokojowe mieszkania są docelowym miejscem zamieszkania rodzin z dziećmi. Ponieważ metraż 4
                pokojowych nieruchomości
                przekracza zazwyczaj 60 metrów mamy spore możliwości
                aranżacji i modyfikacji takich lokali mieszkalnych.</p>

            <p>Dlatego 4 pokojowe rzuty mieszkań w aplikacji archiplaner powinny ułatwić tworzenie pierwszych <b>własnych
                koncepcji naszych
                mieszkań.</b></p>


            <Images>
                <Row className="images">
                    <Col md={6}>
                        <Image src="/images/rzuty-mieszkan/4-pok/1.4-pok-63-m2.png"
                               alt="4 pokojowy rzut mieszkania 63 metry"/>
                    </Col>
                    <Col md={6}>
                        <Image src="/images/rzuty-mieszkan/4-pok/2.4-pok-69-m2.png"
                               alt="4 pokojowy rzut mieszkania 69 metrów"/>
                    </Col>
                </Row>
                <Row className="images">
                    <Col md={6}>
                        <Image src="/images/rzuty-mieszkan/4-pok/3.4-pok-85-m2.png"
                               alt="4 pokojowy rzut mieszkania 85 metrów"/>
                    </Col>
                    <Col md={6}>
                        <Image src="/images/rzuty-mieszkan/4-pok/4.4-pok-93-m2.png"
                               alt="4 pokojowy rzut mieszkania 93 metry"/>
                    </Col>
                </Row>
            </Images>


            <h4 className="anchor" id="5-pok">Rzuty mieszkań 5 pokojowych</h4>

            <p>W programie archiplaner aktualnie przygotowaliśmy sześć dostępnych szablonów 5 pokojowych rzutów
                mieszkań.</p>

            <p>Jeśli chcesz dowiedzieć się jak jak będzie wyglądało Twoje 5 pokojowe mieszkanie w aplikacji archiplaner
                - wystarczy, że
                narysujesz swoje pomieszczenia na kartce i zrobisz jej zdjęcie.
                Następnie dodasz zdjęcie jako podkład do archiplaner i z łatwością wprowadzisz rzut do aplikacji.</p>

            <p>Poniżej przesyłamy kilka dostępnych 5 pokojowych rzutów mieszkań:</p>

            <Images>
                <Row className="images">
                    <Col md={6}>
                        <Image src="/images/rzuty-mieszkan/5-pok/1.5-pok-90-m2.png"
                               alt="5 pokojowy rzut mieszkania 34 metry"/>
                    </Col>
                    <Col md={6}>
                        <Image src="/images/rzuty-mieszkan/5-pok/2.5-pok-116-m2.png"
                               alt="5 pokojowy rzut mieszkania 34 metry"/>
                    </Col>
                </Row>
                <Row className="images">
                    <Col md={6}>
                        <Image src="/images/rzuty-mieszkan/5-pok/3.5-pok-123-m2.png"
                               alt="5 pokojowy rzut mieszkania 34 metry"/>
                    </Col>
                    <Col md={6}>
                        <Image src="/images/rzuty-mieszkan/5-pok/4.5-pok-127-m2.png"
                               alt="5 pokojowy rzut mieszkania 34 metry"/>
                    </Col>
                </Row>
            </Images>


            <h4 className="anchor" id="rzuty-domow">Rzuty domów</h4>

            <p>Poniżej przedstawiamy kilka propozycji rzutów domów. W archiplaner znajdziesz rzuty domów
                jednorodzinnych, bliźniaków oraz domów
                szeregowych.</p>

            <Images>
                <Row className="images">
                    <Col md={6}>
                        <Image src="/images/rzuty-mieszkan/domy/1.Dom-177-m2.png"
                               alt="1 pokojowy rzut mieszkania 34 metry"/>
                    </Col>
                    <Col md={6}>
                        <Image src="/images/rzuty-mieszkan/domy/2.Dom-192-m2.png"
                               alt="1 pokojowy rzut mieszkania 34 metry"/>
                    </Col>
                </Row>
                <Row className="images">
                    <Col md={6}>
                        <Image src="/images/rzuty-mieszkan/domy/3.Dom-202-m2.png"
                               alt="1 pokojowy rzut mieszkania 34 metry"/>
                    </Col>
                    <Col md={6}>
                        <Image src="/images/rzuty-mieszkan/domy/4.Dom-295-m2.png"
                               alt="1 pokojowy rzut mieszkania 34 metry"/>
                    </Col>
                </Row>
            </Images>


            <h2 className="anchor" id="poziomy-rzut">Poziomy rzut mieszkania</h2>

            <p>W aplikacji archiplaner mamy możliwość przygotowania wyłącznie poziomego rzutu mieszkania, który
                rozumiany jest jako rzut 2D.
                Rzut mieszkania z góry może być przydatny jeżeli:</p>
            <ul>
                <li>Chcemy przygotować koncepcję zmian lokatorskich zakładających przebudowę ściany działowej, zmianę
                    punktów świetlnych czy
                    włączników i gniazdek.
                </li>
                <li>Planujemy zamówić zabudowę kuchni i dostosować zmiany istniejących przyłączy wody.</li>
                <li>Chcemy przetestować różne koncepcje aranżacji mebli, łóżka, stołu bez konieczności ich faktycznego
                    przestawiania.
                </li>
                <li>Mamy przygotowanie zdjęcia nieruchomości i chcemy ułatwić orientację odbiorcy oglądającemu
                    ogłoszenie.
                </li>
            </ul>

            <Image
                src="/images/rzuty-mieszkan/10.rzut-zlotu-ptaka.jpg"
                alt="plan mieszkania z góry"
                className="mb-3 d-block"
            />

            <p>Istotnym jest fakt, że rzut poziomy mieszkania zawsze stanowi bazę do przygotowania planu nieruchomości
                3D.</p>

            <p>Nanosimy poszczególne ściany wraz z wymiarami, dodajemy drzwi i okna, meblujemy, a następnie generujemy
                rzut 3D w dedykowanym do
                tego programie.</p>

            <p><b>Poziome rzuty mieszkań</b> są bardzo popularne i spotykane przede wszystkim w:</p>
            <ul>
                <li>ogłoszeniach nieruchomości na portalach.</li>
                <li>inwestycjach deweloperskich lokali mieszkaniowych</li>
                <li>w projektach i koncepcjach aranżacji powierzchni</li>
            </ul>

            <h3 className="anchor" id="poziome-rzuty">Poziome rzuty mieszkań w Archiplaner</h3>

            <p>Poniżej pokażemy na gotowych przykładach zastosowanie programu do rzutów Archiplaner z wykorzystaniem
                kilku dostępnych funkcji
                dzięki, którym możemy dostosować plan mieszkania do własnych
                potrzeb.</p>

            <b>Plan mieszkania z siatką do rysowania</b>
            <p>Siatka na rzucie ułatwia odrysowanie równoległych ścian oraz sprawia, że łączenie ścian nośnych i
                działowych jest prostsze.
                Poniżej przykład zastosowania siatki w praktyce:</p>

            <Image src="/images/rzuty-mieszkan/11.plan-mieszkania-z-siatka.png"
                   alt="siatka ułatwiająca rysowanie planu mieszkania"
                   className="mx-auto mb-3 d-block"
            />

            <b>Rzuty mieszkań z legendą</b>
            <p>Legenda na rzucie może stanowić wartościowe źródło informacji dla osób, które pierwszy raz widzą plan
                mieszkania. Stanowi
                zbiorcze podsumowanie wszystkich informacji przedstawionych na
                planie nieruchomości.</p>

            <Image
                src="/images/rzuty-mieszkan/12.legenda-na-rzucie-mieszkania.png"
                alt="legenda na planie mieszkania"
                className="mx-auto mb-3 d-block"
            />

            <b>Rzuty mieszkań z podkładem</b>
            <p>Podkład stanowi wizualną wskazówkę ułatwiającą nam narysowanie poprawnego planu nieruchomości zachowując
                przy tym proporcję. Żeby
                tworzony przez nas rzut z wykorzystaniem podkładu był
                dokładny pod względem metrażu powinniśmy odpowiednio dostosować <b>skalę podkładu</b>.</p>

            <p>Wystarczy, że dostosujemy wielkość podkładu do narysowanego pomieszczenia w aplikacji archiplaner dzięki
                czemu mamy zachowane
                odpowiednie proporcje.</p>

            <h2 className="anchor" id="rzut-techniczny">Rzut techniczny mieszkania - kiedy jest potrzebny?</h2>

            <p>Czym jest techniczny rzut mieszkania i kiedy go potrzebujemy? Technicznym rzutem jest zwymiarowany lokal
                mieszkalny posiadający
                informację o rozprowadzonej instalacji elektrycznej,
                wodno-kanalizacyjnej oraz o włącznikach i punktach świetlnych.</p>

            <p>Informacje zawarte w technicznym planie mieszkania dostarczają firmie budowlanej i firmie wykończeniowej
                niezbędne wskazówki oraz
                wytyczne dzięki, którym firma wprowadzi naszą koncepcję w
                życie. Dlatego powinniśmy znać podstawowe symbole nieruchomości, które pojawiają się na technicznym
                rzucie mieszkania.</p>

            <h3 className="anchor" id="symbole-planu">Symbole na planie mieszkania</h3>

            <p>Poza dokładnymi wymiarami poszczególnych ścian istotną rolę odgrywają <a
                href="/symbole-na-planie-mieszkania/" target="blank">symbole na planie mieszkania</a>, które są źródłem
                informacji
                niezbędnym do prawidłowego odczytania rzutu
                nieruchomości.</p>

            <p>Poniższa przygotowana lista stanowi podstawowy zbiór informacji o elementach pojawiających się na planie
                mieszkania.
                Przedstawiliśmy również jak poniższe symbole prezentują się w naszym
                programie do rzutów:</p>

            <b>Ściany nośne i działowe</b>
            <p>Ściany stanowią główny element informujący nas o rozkładzie mieszkania. W zależności od nośności i
                przeznaczenia ścian wyróżniamy
                ściany nośne i ściany działowe. Ściany nośne służą do
                przenoszenia obciążenia budynku wraz ze stropem i dachem. Ściany działowe służą głównie do podziału
                całej nieruchomości na
                określone powierzchnie.</p>

            <b>Słupy konstrukcyjne</b>
            <p>Słupy konstrukcyjne służą również do odciążenia budynku. Istotnie wpływają na wytrzymałość ścian nośnych.
                Pojawiają się w
                istotnych miejscach nieruchomości, aby dodatkowo wzmocnić nośność
                ścian nośnych.</p>

            <b>Oznaczenia okien</b>
            <p>Okna są istotnym elementem przy tworzeniu podziału nieruchomości na poszczególne pomieszczenia. To w
                jakim miejscu znajdują się
                okna istotnie wpływa na dodatkowe podziały pokoi czy też
                wydzielenie osobnej kuchni.</p>

            <Image src="/images/rzuty-mieszkan/13.oznaczenie-okien.png"
                   alt="rodzaje okien dostępne na rzucie mieszkania"
                   className="mx-auto mb-3 d-block"
            />

            <b>Drzwi wejściowe i wewnętrzne</b>
            <p>Drzwi na rzucie są symbolem komunikacji pomiędzy pomieszczeniami.</p>

            <Image src="/images/rzuty-mieszkan/14.oznaczenie-drzwi.png"
                   alt="rodzaje drzwi dostępne na planie mieszkania"
                   className="mx-auto mb-3 d-block"/>

            <b>Schody</b>
            <p>Schody oddzielają poszczególne poziomy nieruchomości i stanowią również element komunikacji w
                nieruchomości.</p>

            <Image src="/images/rzuty-mieszkan/15.oznaczenie-schodow.png"
                   alt="rodzaje schodów dostępne na rzucie nieruchomości"
                   className="mx-auto mb-3 d-block"/>

            <b>Poziomy nieruchomości</b>
            <p>Na planie poziomy pojawiają się jako osobne nieruchomości zazwyczaj usytuowane obok siebie albo pod sobą.
                W zależności od źródła
                rzutu technicznego oznaczenie kondygnacji może się
                różnić.</p>

            <b>Poddasze użytkowe / nieużytkowe</b>
            <p>Oznaczenia poddasza pojawia się głównie w domach jednorodzinnych, bliźniakach, szeregówkach i
                mieszkaniach z antresolą. Sposób w
                jaki poddasze użytkowe/nieużytkowe przedstawione jest na
                rzucie istotnie wpływa na zrozumienie technicznego rzutu.</p>

            <p>Należy rozróżnić, która część poddasza wliczana jest w powierzchnię użytkową nieruchomości, a która
                powierzchnia stanowi jej
                część nie użytkową.</p>

            <b>Wentylacja - piony i szyby wentylacyjne</b>
            <p>Prawidłowe zlokalizowanie pionu wentylacyjnego jest podstawą do wydzielenia łazienki oraz kuchni.
                Poprawne oznaczenie na rzucie
                pionu wentylacyjnego może być kluczowe przy sporządzaniu
                planów lokali usługowych przeznaczonych pod działalność gastronomiczną.</p>

            <b>Instalacja elektryczna / wodno-kanalizacyjna</b>
            <p>Na technicznym rzucie mieszkania znajdziemy również informacje o rozmieszczeniu instalacji elektrycznej w
                poszczególnych
                pomieszczeniach. Wiedza o dokładnej lokalizacji instalacji
                elektrycznej może być nieoceniona w przyszłości jeśli decydujemy się na remont czy przebudowę
                pomieszczeń.</p>

            <p>Wykonanie adaptacji nieruchomości naruszając instalację elektryczną może narazić nas na dodatkowe koszty
                plus czas bez dostępu do
                prądu. Sytuacja jest podobna przy instalacji
                wodno-kanalizacyjnej. Jej uszkodzenie może narazić nas na dodatkowe koszty.</p>

            <b>Punkty świetlne / gniazdka i włączniki</b>
            <p>W szczególności przy zakupie nieruchomości z rynku pierwotnego gdzie termin oddania nieruchomości jest
                odległy mamy możliwość
                wprowadzić zmiany lokatorskie jeszcze zanim budynek zostanie
                wybudowany.</p>

            <p>Wówczas praca z technicznym rzutem mieszkania oraz wyłącznikami, gniazdkami i punktami świetlnymi jest
                podstawą. Na tym etapie
                mamy możliwość dostosować rozmieszczenie instalacji
                elektrycznej w taki sposób, żeby realizacja naszej koncepcji aranżacji była możliwa.</p>

            <p>Jest to istotne ponieważ, niektórzy deweloperzy dokonują zmian lokatorskich w cenie zakupu nieruchomości
                jeśli budynek nie został
                jeszcze wybudowany oraz jeżeli zakres prac nie przekroczy
                ustaleń dewelopera.</p>

            <b>Strony świata - <a href="/program-do-rzutow/#Kierunki_swiata_w_rzucie" target="blank">róża
                wiatrów</a></b>
            <p>Zlokalizowanie północy na technicznym planie nieruchomości jest istotne z kilku względów. Przede
                wszystkim ekspozycja okien na
                określone strony świata może zapewnić więcej dziennego
                światła, bądź je ograniczyć.</p>

            <Image
                src="/images/rzuty-mieszkan/16.roza-wiatrow-na-planie.png"
                alt="strony świata na rzucie mieszkania"
                className="mx-auto mb-3 d-block"/>

            <p>Ma to znaczenie w szczególności w jesienno-zimowym czasie oraz w lecie podczas gorących upałów.</p>

            <p><b>Lista symboli pojawiających się na planie mieszkania</b> może być dla nas ważnym źródłem informacji
                dlatego powinniśmy
                zapoznać się z najczęściej pojawiającymi się symbolami na rzucie
                nieruchomości.</p>

            <h3 className="anchor" id="kto-techniczny">Od kogo otrzymamy techniczny rzut mieszkania</h3>

            <p><a href="/techniczny-rzut-mieszkania/" target="blank">Techniczny plan mieszkania</a> otrzymamy zawsze
                jeśli zlecimy
                wykonanie dokumentacji i pomiarów naszej nieruchomości architektowi.
                Dodatkowo każdym pozwoleniu na budowę poza wnioskiem o
                pozwolenie na budowę składany jest również projekt architektoniczno-budowlany, który zawiera właśnie
                techniczne informacje o
                nieruchomości.</p>

            <p>Również na prośbę dewelopera przy zakupie nieruchomości możemy otrzymać techniczny rzut mieszkania naszej
                nieruchomości.
                Standardowo tego typu plany nie są dołączane do oferty dewelopera na
                portalach.</p>

            <p>Natomiast jeśli pojawi się taka potrzeba należy kierować zapytanie do działu sprzedaży dewelopera, który
                uzyska informacje od
                biura projektowego przygotowującego projekt
                architektoniczno-budowlany.</p>

            <h2 className="anchor" id="rzut-bloku">Rzut mieszkania w bloku</h2>

            <p>Rzut mieszkania w bloku jest planem nieruchomości zlokalizowanym na określonym piętrze budynku. Czym
                różni się się rzut
                mieszkania w bloku od zwykłego planu mieszkania?</p>

            <Images>
                <Row className="images">
                    <Col md={6} className="mx-auto">
                        <Image src="/images/rzuty-mieszkan/17.rzut-mieszkania-w-bloku.png" alt="rzut mieszkania w bloku"/>
                    </Col>
                </Row>
            </Images>

            <p>Jedyna różnica może polegać na tym, że posiadając rzut mieszkania w bloku wraz z przyległymi
                nieruchomościami zlokalizowanymi na
                tym samym poziomie, uzyskamy informację o rozkładzie i
                metrażu sąsiadujących lokali.</p>

            <p>Co w sytuacji zakupu kilku nieruchomości na jednym piętrze może być pomocne. </p>
        </Container>
    </>
}