import React from 'react'
import ReactDOM from 'react-dom'
import {AppContainer} from 'react-hot-loader'

// Your top level component
import App from './App'
import {addPrefetchExcludes} from "react-static";

// addPrefetchExcludes([ process.env.REACT_STATIC_SITE_ROOT+"/app", '/app', '/app/', process.env.REACT_STATIC_SITE_ROOT+"/draw", '/draw', '/draw/' ] );//
addPrefetchExcludes([ (path) => {
  console.log("Testing path: ", path);
  return false;
} ] );//

// Export your top level component as JSX (for static rendering)
export default App

// Render your app
if (typeof document !== 'undefined') {
  const target = document.getElementById('root')

  const renderMethod = target.hasChildNodes()
    ? ReactDOM.hydrate
    : ReactDOM.render

  const render = Comp => {
    renderMethod(
      <AppContainer>
        <Comp />
      </AppContainer>,
      target
    )
  }

  // Render!
  render(App)

  // Hot Module Replacement
  if (module && module.hot) {
    module.hot.accept('./App', () => {
      render(App)
    })
  }
}
