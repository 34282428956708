//@flow
import React, {useCallback} from 'react';
import {Head, useRouteData, useSiteData} from "react-static";
import {Col, Container, Row} from "react-bootstrap";
import { Icon } from "./lib/Icon";
import {Link, useHistory} from 'react-router-dom'
import type {HelpMeta, SiteDataMeta} from "./static-api";

export default () => {
    let data=useRouteData<HelpMeta>();
    const { siteRoot, helpPath } = useSiteData<SiteDataMeta>();

    const history=useHistory();

    const processContent=useCallback((div: HTMLDivElement) => {
        if(!div) return;    // unmount
        for(let a: HTMLAnchorElement of div.getElementsByTagName("a")) {
            const href=a.getAttribute("href");
            const isBlank=a.getAttribute("target")==="_blank";
            if(isBlank) return; // w nowym oknie domyślnie
            if(href && a.hasAttribute("data-link")) {
                a.onclick=(e: Event) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const hp=href.indexOf('#');
                    if(hp>0) {
                        history.push({
                            pathname: href.substr(0, hp),
                            hash: href.substr(hp+1)
                        });
                    } else {
                        history.push({
                            pathname: href
                        });
                    }
                }
            } else if(href && href.startsWith("#")) {
                a.onclick=(e: Event) => {
                    e.preventDefault();
                    e.stopPropagation();
                    history.push({
                        pathname: history.location.pathname,
                        hash: href
                    })
                }
            }
        }

    }, [ history ]);

    let head=[];
    if(Array.isArray(data.description)) {
        data.description.forEach((desc, index) => head.push(<meta key={index} name="description" content={desc}/>))
    } else if(typeof(data.description)==='string') {
        head.push(<meta name="description" key="s" content={data.description}/>);
    }

    // console.log("Props: ", data);
    return <>
        <Head>
            <title>{data.metaTitle || data.title}</title>
            <link rel="canonical" href={siteRoot+"/poradnik/"+data.name}/>
            {head}
        </Head>
        <Container className="p-section text-content">
            <div className="text-center pb-5">
                <Link to="/poradnik">
                    <Icon.Back className="guide-icon"/> Wróć do spisu treści
                </Link>
            </div>
            <h1 className="text-center pb-5">{data.h1Title || data.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: data.content }} ref={processContent}/>
            <div className="pb-5"/>
            <hr/>
            <div className="text-center py-4">
                <Link to="/poradnik">
                    <Icon.Back className="guide-icon"/> Wróć do spisu treści
                </Link>
            </div>
            <hr className="py-3"/>
            <Row className="pb-5">
                <Col md={1}/>
                <Col md={10} className="text-center">
                    <h2 className="uppercase">Zobacz jak wiele zyskasz</h2>
                    <p>Stwórz skuteczną ofertę, która przyciągnie uwagę klientów</p>
                </Col>
                <Col md={1}/>
            </Row>
            <Row>
                <Col md={4} className="text-center pb-5">
                    <Icon.Favourite className="svg-icon"/>
                    <p>Wyróżnij się na tle innych ofert</p>
                </Col>
                <Col md={4} className="text-center pb-5">
                    <Icon.SketchBig className="svg-icon"/>
                    <p>Przygotuj spersonalizowaną prezentację</p>
                </Col>
                <Col md={4} className="text-center pb-5">
                    <Icon.SafeHome className="svg-icon"/>
                    <p>Przedstaw kluczowe cechy nieruchomości</p>
                </Col>
            </Row>
            <Row>
                <Col md={2}/>
                <Col md={4} className="text-center pb-5">
                    <Icon.LocationOk className="svg-icon"/>
                    <p>Zwiększ wiarygodność oferty</p>
                </Col>
                <Col md={4} className="text-center pb-5">
                    <Icon.DocumentHome className="svg-icon"/>
                    <p>Zadbaj o czytelność ogłoszenia</p>
                </Col>
                <Col md={2}/>
            </Row>
            <Row>
                <Col md={12} className="text-center">
                    <a href="/app/" className="btn btn-primary">Wypróbuj za darmo</a>
                </Col>
            </Row>
        </Container>
    </>;
}