//@flow
import React, {useMemo} from 'react';
import {Head, useRouteData, useSiteData} from "react-static";
import {Col, Container, Row} from "react-bootstrap";
import {Icon} from "../lib/Icon";
import {useHistory} from 'react-router-dom'
import type {HelpMeta, SectionMeta, SiteDataMeta} from "../static-api";

type Category = {
    name: string;
    title: string;
    section?: string;
}

const HelpLink = ({path, value}: { path: string, value: Category }) => {
    const history=useHistory();
    let hash=value.section;
    if(hash && !hash.startsWith("#")) hash="#"+hash;
    return <a
        href={path+value.name+(hash || "")}
        onClick={(e) => {
            e.preventDefault(); e.stopPropagation();
            history.push({
                pathname: path+value.name,
                hash: hash
            });
        }}
    >{value.title}</a>
}

export default () => {
    const {pages }=useRouteData<{pages: Array<HelpMeta>}>();
    const { siteRoot, helpPath } = useSiteData<SiteDataMeta>();
    const categories=useMemo<Map<string, Array<Category>>>(() => {
        let res=new Map<string, Array<Category>>;
        const add=(cat, section) => {
            if(!cat) return;
            let a=res.get(cat);
            if(!a) res.set(cat, a=[]);
            a.push(section);
        }
        pages.forEach((p:HelpMeta) => {
            add(p.category, { name: p.name, title: p.title });
            if(Array.isArray(p.sections)) {
                p.sections.forEach((s: SectionMeta) => {
                    add(s.category, {
                        name: p.name, title: s.title, section: s.section
                    });
                })
            }
        });
        res.forEach((v: Array<Category>) => {
            v.sort((c1, c2) => c1.title.toLocaleLowerCase().localeCompare(c2.title.toLocaleLowerCase()))
        })

        return res;
    }, []);

    return (<>
        <Head>
            <title>Poradnik</title>
            <meta name="description" content="Narysuj swój pierwszy plan mieszkania w programie do rzutów dostępnym online. Stwórz nowy rzut mieszkania, domu czy lokalu w Archiplaner."/>
        </Head>
        <Container className="p-section">
            <h1 className="text-center pb-3">Poradnik dla użytkowników Archiplanera</h1>
            <p className="text-center">Poznaj możliwości aplikacji uwielbianej przez agentów nieruchomości.</p>
            <Row>
                <Col md={4} className="pb-3">
                    <Icon.Help className="guide-icon"/>
                    <span>Podstawowe informacje</span>
                    <hr/>
                    <ul>
                        {categories.get("basic").map((p, index) => <li key={index}>
                            <HelpLink path={helpPath} value={p}/>
                        </li>)}
                    </ul>
                </Col>
                <Col md={4} className="pb-3">
                    <Icon.Settings className="guide-icon"/>
                    <span>Konfiguracja konta</span>
                    <hr/>
                    <ul>
                        {categories.get('account').map((p, index) => <li key={index}>
                            <HelpLink path={helpPath} value={p}/>
                        </li>)}
                    </ul>
                </Col>
                <Col md={4} className="pb-3">
                    <Icon.Sketch className="guide-icon"/>
                    <span>Rzut nieruchomości</span>
                    <hr/>
                    <ul>
                        {categories.get('sketch').map((p, index) => <li key={index}>
                            <HelpLink path={helpPath} value={p}/>
                        </li>)}
                    </ul>
                </Col>
            </Row>
            <Row>
                <Col md={4} className="pb-3">
                    <Icon.Furniture className="guide-icon"/>
                    <span>Wyposażenie</span>
                    <hr/>
                    <ul>
                        {categories.get('furniture').map((p, index) => <li key={index}>
                            <HelpLink path={helpPath} value={p}/>
                        </li>)}
                    </ul>
                </Col>
                <Col md={4} className="pb-3">
                    <Icon.Export className="guide-icon"/>
                    <span>Eksport</span>
                    <hr/>
                    <ul>
                        {categories.get('export').map((p, index) => <li key={index}>
                            <HelpLink path={helpPath} value={p}/>
                        </li>)}
                    </ul>
                </Col>
                <Col md={4} className="pb-3">
                    <Icon.New className="guide-icon"/>
                    <span>Pozostałe</span>
                    <hr/>
                    <ul>
                        {categories.get('other').map((p, index) => <li key={index}>
                            <HelpLink path={helpPath} value={p}/>
                        </li>)}
                    </ul>
                </Col>
            </Row>
        </Container>
        <Container className="mw-md p-section mx-auto">
            <Row className="pb-5">
                <Col md={1}/>
                <Col md={10} className="text-center">
                    <h2 className="uppercase">Zobacz jak wiele zyskasz</h2>
                    <p>Stwórz skuteczną ofertę, która przyciągnie uwagę klientów</p>
                </Col>
                <Col md={1}/>
            </Row>
            <Row>
                <Col md={4} className="text-center pb-5">
                    <Icon.Favourite className="svg-icon"/>
                    <p>Wyróżnij się na tle innych ofert</p>
                </Col>
                <Col md={4} className="text-center pb-5">
                    <Icon.SketchBig className="svg-icon"/>
                    <p>Przygotuj spersonalizowaną prezentację</p>
                </Col>
                <Col md={4} className="text-center pb-5">
                    <Icon.SafeHome className="svg-icon"/>
                    <p>Przedstaw kluczowe cechy nieruchomości</p>
                </Col>
            </Row>
            <Row>
                <Col md={2}/>
                <Col md={4} className="text-center pb-5">
                    <Icon.LocationOk className="svg-icon"/>
                    <p>Zwiększ wiarygodność oferty</p>
                </Col>
                <Col md={4} className="text-center pb-5">
                    <Icon.DocumentHome className="svg-icon"/>
                    <p>Zadbaj o czytelność ogłoszenia</p>
                </Col>
                <Col md={2}/>
            </Row>
            <Row>
                <Col md={12} className="text-center">
                    <a href="/app/" className="btn btn-primary">Wypróbuj za darmo</a>
                </Col>
            </Row>
        </Container>
    </>)
};