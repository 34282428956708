//@flow
import {atom, selector, useRecoilCallback, useRecoilValue} from 'recoil';
import type {UserInfo} from "../api";
import {publicApi, userApi} from "./Network";
import {ueEvent} from "./UserCom";

export const rememberMeKey="remember_me";

const userRefreshAtom=atom<number>({
    key: 'userRefresh',
    default: 0
});

/**
 * Informacje o stanie zalogowania użytkownika.<br/>
 * false = nie sprawdzano stanu, null = nie zalogowany, dane to zalogowany użytkownik.
 **/
export const userAtom=atom<UserInfo|null>({
    key: 'user',
    default: selector({
        key: 'user/initial',
        get: async ({get}) => {
            if(!window) return null;    // dla React-Static build
            get(userRefreshAtom);   // na potrzeby wymuszenia odświeżenia
            const token = window.localStorage.getItem(rememberMeKey);
            console.log("Checking for active session with token:", token);
            const res = await publicApi.getUser(token || null);
            if (res) return res; // jeżeli udało się obrać
            if (token) { // jak nie i jest token
                window.localStorage.removeItem(rememberMeKey);  // to go usuwamy
            }
            return null;
        },
    }),
})

/**
 * Hook, który zwraca aktualny stan zalogowania użytkownika.
 */
export function useUserSession(): UserInfo|null {
    return useRecoilValue(userAtom);
}

export function useLogoutCallback(queryClient?: { clear: () => void }): () => void {
    return useRecoilCallback(({set }) => async() => {
        const token=window.localStorage.getItem(rememberMeKey);
        if(token) window.localStorage.removeItem(rememberMeKey)
        await userApi.logout(token || null);
        ueEvent("archi_wyloguj").finally();
        set(userAtom, null);
        if(queryClient) queryClient.clear();
    }, [queryClient])
}

export function useUserRefreshCallback(): () => void {
    return useRecoilCallback(({ set }) => () => {
        set(userRefreshAtom, (counter) => counter+1)
    })
}

export type UserPreferences = {
    exportFormat?: "JPEG"|"PNG",
}

const NO_PREFS={};

let saveHandle=null;

/**
 * Hook udostępniający użytkownika ustawienia oraz funkcję modyfikacji ustawień (wysłania zmiany do serwera)
 */
export function useUserPreferences(): [ UserPreferences, (change: $Shape<UserPreferences>) => void ] {
    const user=useRecoilValue(userAtom);
    const prefs=(user && user.webPreferences)?user.webPreferences:NO_PREFS;
    const saveSettingsFunction=useRecoilCallback(({ set }) => async(change: $Shape<UserPreferences>) => {
        if(!user) return;   // brak sesji zalogowania, nie zapisujemy ustawień
        const oldJson=JSON.stringify(prefs);
        const newSettings=Object.assign({}, prefs, change);
        const newJson=JSON.stringify(newSettings);
        if(oldJson===newJson) return;   // brak zmian
        set(userAtom, {
            ...user,
            webPreferences: newSettings
        });
        // Opóźnione zapisywanie dla sytuacji zmiany wielu wartości, czy ciągłego klikania.
        if(saveHandle) window.clearTimeout(saveHandle);
        saveHandle=window.setTimeout(() => {
            saveHandle=null;
            userApi.saveUserPreferences(newJson);
        }, 1500);
    }, [ user, prefs ]);

    return [ prefs, saveSettingsFunction ];
}