//@flow
import React, {useEffect, useState} from "react";

function cookiesAccepted(): boolean|null {
    if(typeof(window)==='undefined') return null;   // dla react-static
    return !!window.localStorage.getItem("cookie_agreement");

}

/**
 * Komponent informujący o tym, że strona przetwarza ciasteczka.
 */
export const CookieInfo = () => {
    const [ accepted, setAccepted ] = useState(cookiesAccepted());
    // Na potrzeby react-static
    useEffect(() => {
        if(accepted!==null) return undefined;
        setAccepted(cookiesAccepted());
    }, [ accepted, setAccepted ]);

    return (accepted===null || accepted)?null:<div className="cookie-info">
        <p>Użytkowanie serwisu oznacza zgodę na wykorzystywanie plików cookies zgodnie z <a href="/polityka-prywatnosci/">polityką prywatności</a>.</p>
        <div>
            <button onClick={() => {
                if(typeof(window)==='undefined') return;
                window.localStorage.setItem("cookie_agreement", "1");
                setAccepted(true);
            }}>Akceptuję politykę prywatności</button>
        </div>
    </div>
};