//@flow
import React from 'react';
import {Head, useRouteData} from "react-static";
import type {NewsPage} from "./static-api";
import {Container} from "react-bootstrap";

/**
 * Strona z aktualnościami działa w dwóch trybach:
 * - wyświetlenie listy aktualności (lista postów),
 * - wyświetlenie konkretnego wpisu.
 */
export default () => {
    const n: NewsPage = useRouteData<NewsPage>();

    return <>
        <Head>
            <title>{n.title}</title>
        </Head>
        <Container className="p-section">
            <h1 className="text-center">{n.title}</h1>
            <div className="text-center pb-3">{n.date}</div>
            <div dangerouslySetInnerHTML={{ __html: n.message }}/>
        </Container>
    </>
}