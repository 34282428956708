import React from 'react';
import {Head} from "react-static";
import {Container} from "react-bootstrap";

export default () => (
    <>
        <Head>
            <title>Regulamin</title>
        </Head>
        <Container className="p-section">
            <h1 className="text-center">Regulamin serwisu internetowego Archiplaner.pl</h1>

            <h3>ROZDZIAŁ I. POSTANOWIENIA OGÓLNE</h3>
            <ol>
                <li>Regulamin określa zasady korzystania przez Użytkowników, w tym Konsumentów, z Serwisu prowadzonego przez Administratora
                    będącego jednocześnie właścicielem serwisu. Serwis dedykowany jest w szczególności osobom fizycznym
                    wykonującym czynności pośrednictwa w obrocie nieruchomościami (w tym agentom nieruchomości prowadzącym własną
                    działalność gospodarczą oraz osobom fizycznym nieprowadzącym własnej
                    działalności gospodarczej, a wykonującym czynności na zlecenie biura nieruchomości), ponadto osobom prawnym i jednostkom
                    organizacyjnym nieposiadającym osobowości prawnej (w
                    szczególności biurom nieruchomości). Serwis oferuje dostęp do szeregu funkcjonalności ułatwiających przygotowywanie
                    ofert nieruchomości, w tym generowanie rzutów nieruchomości.
                </li>
                <li>Regulamin udostępniany jest nieprzerwanie na stronie internetowej Serwisu w sposób umożliwiający Użytkownikom jego
                    pozyskanie, odtwarzanie i utrwalanie jego treści.
                </li>
            </ol>

            <h3>ROZDZIAŁ II. DEFINICJE</h3>
            <p>Terminy użyte w niniejszym Regulaminie oznaczają:</p>
            <ul>
                <li>„<b>Administrator</b>” – Archiplaner sp.z o.o. z siedzibą we Wrocławiu, przy ul. Wita Stwosza 16, zarejestrowana w
                    rejestrze przedsiębiorców Krajowego Rejestru Sądowego
                    prowadzonym przez Sąd Rejonowy dla Wrocławia-Fabrycznej we Wrocławiu VI Wydział Gospodarczy KRS. pod nr KRS 0000880124 ,
                    kapitał zakładowy 5.000 zł wpłacony w całości,
                    NIP: 8971887837, REGON: 387999742; dane do kontaktu: e-mail: <a href="mailto:info@archiplaner.pl">info@archiplaner.pl</a>.
                    który jest jednocześnie właścicielem serwisu;
                </li>
                <li>„<b>Serwis</b>” – Serwis internetowy prowadzony przez Administratora pod adresem archiplaner.pl, za pośrednictwem
                    którego
                    Administrator umożliwia Użytkownikom Rejestrację oraz udostępnia na rzecz Użytkowników Aplikację (sprzedaje dostęp do
                    Aplikacji) na warunkach określonych w niniejszym Regulaminie;
                </li>
                <li>„<b>Użytkownik</b>” – osoba fizyczna, osoba prawna oraz jednostka organizacyjna nieposiadająca osobowości prawnej,
                    odwiedzająca Serwis lub korzystająca z usług Serwisu;
                </li>
                <li>„<b>Rejestracja</b>” – procedura zakładania Konta w Serwisie przez Użytkownika, równoznaczna z zawarciem Umowy pomiędzy
                    Użytkownikiem a Administratorem;
                </li>
                <li>„<b>Konto</b>” – indywidualna witryna Użytkownika zarejestrowanego w Serwisie, udostępniana przez Administratora pod
                    unikalną nazwą adresu e-mail (login), pełni funkcję zbioru zasobów, w których
                    gromadzone są dane Użytkownika oraz informacje na temat jego działań w ramach Serwisu, w tym dokonywanych Subskrypcji.
                    Konto chronione jest hasłem, które tworzy Użytkownik;
                </li>
                <li>„<b>Subskrypcja</b>” – zakup przez Użytkownika dostępu do Aplikacji oferowanej w Serwisie, równoznaczny z zawarciem
                    pomiędzy Użytkownikiem a Administratorem umowy sprzedaży dostępu
                    do Aplikacji w jednym z kilku wybranych pakietów;
                </li>
                <li>„<b>Aplikacja</b>” – nowoczesna intuicyjna aplikacja przeznaczona do generowania (tworzenia) rzutów nieruchomości dla
                    użytkowników, umożliwiająca dostęp do szeregu
                    funkcjonalności, w szczególności takich, jak szkicowanie rozkładu pomieszczeń, dodawanie poszczególnych elementów
                    wyposażenia lokali, eksportowanie rzutów oraz gromadzenie rzutów i szablonów w formie własnej biblioteki. Utworzony
                    dzięki Aplikacji przez Użytkownika rzut nieruchomości umożliwi m.in.
                    przygotowanie spersonalizowanej prezentacji i przedstawienie kluczowych cech nieruchomości w ramach oferty;
                </li>
                <li>„<b>Konsument</b>” – Użytkownik będący osobą fizyczną, dokonujący w Serwisie zakupu dostępu do Aplikacji (czyli
                    Subskrypcji), niezwiązanego bezpośrednio z jego działalnością
                    zawodową lub gospodarczą;
                </li>
                <li>„<b>Polityka Prywatności</b>” – dokument zawierający szczegółowe informacje na temat przetwarzania danych osobowych i
                    zasad ochrony prywatności stosowanych wobec Użytkowników;
                </li>
                <li>„<b>Regulamin</b>” – niniejszy regulamin obejmujący zbiór zasad korzystania z Serwisu (w tym rodzaje i zakres usług
                    świadczonych drogą elektroniczną przez Administratora, warunki
                    ich świadczenia, warunki zawierania i rozwiązywania umów między Administratorem a Użytkownikami w ramach Serwisu, w
                    szczególności warunki dokonywania Subskrypcji) oraz prawa i
                    obowiązki Użytkowników i Administratora, stanowiący regulamin świadczenia usług drogą elektroniczną, zgodnie z Ustawą;
                    Regulamin i warunki w nim określone stanowią wiążącą umowę
                    między Użytkownikiem a Administratorem – dotyczącą użytkowania Serwisu, zwłaszcza po dokonaniu Rejestracji przez
                    Użytkownika.
                </li>
                <li>„<b>Ustawa</b>” – ustawa o świadczeniu usług drogą elektroniczną z dnia 18 lipca 2002 r. (Dz.U. nr 144, poz. 1204 z
                    późn. zm.);
                </li>
                <li>„<b>RODO</b>” – Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z 27 kwietnia 2016 r. w sprawie ochrony
                    osób fizycznych w związku z przetwarzaniem danych osobowych i w
                    sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE.
                </li>
            </ul>

            <h3>ROZDZIAŁ III. OGÓLNE WARUNKI KORZYSTANIA Z SERWISU I DOSTĘP DO SERWISU, OCHRONA WŁASNOŚCI INTELEKTUALNEJ ADMINISTRATORA</h3>
            <ol>
                <li>Użytkownik będący osobą fizyczną może korzystać z Serwisu pod warunkiem posiadania pełnej zdolności do czynności
                    prawnych.
                </li>
                <li>Użytkownik nie będący osobą fizyczną może korzystać z Serwisu za pośrednictwem osób upoważnionych do działania w jego
                    imieniu.
                </li>
                <li>Użytkownik może korzystać z Serwisu za pośrednictwem urządzeń komunikujących się z Internetem (np. komputer osobisty,
                    laptop, tablet), z wykorzystaniem przeglądarki internetowej.
                    Dla umożliwienia poprawnego działania niektórych funkcjonalności Serwisu, może być wymagane zapewnienie przez
                    Użytkownika posiadania przeglądarki internetowej w możliwie
                    najaktualniejszej wersji, obsługującej i akceptującej pliki cookies oraz obsługującej język Javascript. Korzystanie z
                    przeglądarki, która nie spełnia tych wymogów może
                    powodować nieprawidłowości w funkcjonowaniu Serwisu, za które Administrator nie ponosi odpowiedzialności. Korzystając z
                    Serwisu, Użytkownik zgadza się na zapisywanie plików cookies
                    w swoim urządzeniu.
                </li>
                <li>Administrator nie ponosi odpowiedzialności za ewentualne szkody spowodowane podaniem przez Użytkownika nieprawdziwych,
                    nieaktualnych lub niepełnych danych na Koncie lub przy
                    dokonywaniu Subskrypcji. Dane podawane przez Użytkownika nie mogą naruszać przepisów aktualnie obowiązującego prawa oraz
                    dóbr osobistych i praw osób trzecich.
                </li>
                <li>O ile niniejszy Regulamin lub Serwis nie stanowią inaczej, własność intelektualną Administratora stanowią:
                    <ol className="legal-list">
                        <li>wszystkie strony internetowe dostępne w Serwisie, a także strony internetowe, do których odsyła Serwis,</li>
                        <li>wszystkie materiały i informacje zamieszczone w Serwisie, w tym w szczególności wszelkie teksty, grafika,
                            fotografie, pliki audio, filmy, aplikacje komputerowe, funkcje
                            interaktywne, wszelkie materiały chronione prawami autorskimi (w tym kod źródłowy i obiektowy) – za wyjątkiem
                            materiałów i informacji umieszczanych, transmitowanych,
                            udostępnianych i publikowanych przez Użytkowników,
                        </li>
                        <li>dobór, organizacja, struktura i kompilacja materiałów, układ treści oraz ogólny wygląd, styl i charakter
                            Serwisu,
                        </li>
                        <li>wszystkie nazwy handlowe, znaki towarowe, marki usług, logo, nazwy domen i inne charakterystyczne elementy
                            marki, niezależnie od ich rejestracji.
                        </li>
                    </ol>
                </li>
                <li>Własność intelektualna Administratora wskazana w ust. 5 wyżej, podlega ochronie przez prawa autorskie, prawa do wzorów,
                    prawa patentowe, prawa do znaków towarowych i inne przepisy,
                    w tym konwencje międzynarodowe i prawa własności. Bez wyraźnej pisemnej zgody Administratora, Użytkownik nie ma prawa
                    sprzedawać, rozpowszechniać, kopiować, modyfikować, odtwarzać,
                    pokazywać ani nadawać publicznie, publikować, edytować ani adaptować tych elementów, udzielać licencji na nie, tworzyć
                    na ich podstawie dzieł pochodnych, ani wykorzystywać ich w
                    inny sposób. Korzystając z Serwisu, Użytkownik zobowiązuje się przestrzegać wszystkich przepisów prawa dotyczących
                    własności intelektualnej, w tym w szczególności ustawy o prawie
                    autorskim i prawach pokrewnych z dnia 4 lutego 1994 r. (Dz.U. Nr 24, poz. 83 z późn. zm.) oraz ustawy Prawo własności
                    przemysłowej z dnia 30 czerwca 2000 r. (Dz.U. 2001 Nr 49, poz.
                    508 z późn. zm.).
                </li>
                <li>Użytkownik dokonując Rejestracji, uzyskuje możliwość dokonywania płatnych Subskrypcji i korzystania z innych
                    funkcjonalności oferowanych przez Serwis.
                </li>
            </ol>

            <h3>ROZDZIAŁ IV. PROCEDURA REJESTRACJI W SERWISIE, KONTO UŻYTKOWNIKA</h3>
            <ol>
                <li>W celu założenia Konta Użytkownik powinien dokonać Rejestracji poprzez wypełnienie formularza rejestracyjnego,
                    udostępnianego w ramach Serwisu. Podczas Rejestracji Użytkownik ma
                    obowiązek podać aktualny adres e-mail (login), oraz hasło, a także dokonać akceptacji Regulaminu oraz Polityki
                    Prywatności.
                </li>
                <li>Po weryfikacji danych objętych formularzem rejestracyjnym, Użytkownik otrzymuje od Administratora na podany przez siebie
                    aktualny adres poczty elektronicznej (adres e-mail)
                    wiadomość o założeniu Konta. By pomyślnie zakończyć procedurę Rejestracji, Użytkownik powinien dokonać aktywacji Konta
                    poprzez kliknięcie na link przesłany pocztą elektroniczną do
                    Użytkownika razem z informacją o założeniu Konta.
                </li>
                <li>Dla Użytkownika, który dokonał Rejestracji tworzone jest Konto. Użytkownik może edytować swoje dane podane przy
                    Rejestracji (hasło) na profilu Konta.
                    Użytkownik ma ponadto możliwość podania swoich danych osobowych – imienia i nazwiska, telefonu, zdjęcia oraz logo firmy
                    i nazwy swojego biura dostępne w "pakiecie biurowym" w ramach którego wykonuje czynności w
                    zakresie pośrednictwa w obrocie nieruchomościami.
                </li>
                <li>W sytuacji prawidłowego zarejestrowania się zgodnie z ust. 1 i 2, Użytkownik otrzymuje dostęp do funkcjonalności Serwisu
                    po podaniu loginu oraz hasła na stronie logowania, dzięki
                    czemu może dokonywać Subskrypcji i korzystać z Aplikacji.
                </li>
                <li>Użytkownik w ramach Konta ma możliwość śledzenia płatności za dokonane Subskrypcje.</li>
            </ol>

            <h3>ROZDZIAŁ V. SUBSKRYPCJE, RODZAJE (PAKIETY) APLIKACJI, PŁATNOŚCI</h3>
            <ol>
                <li>Dokonanie Subskrypcji jest równoznaczne z zawarciem między Administratorem a Użytkownikiem umowy sprzedaży dostępu do
                    Aplikacji oferowanej w Serwisie.
                </li>
                <li>Dokonanie Subskrypcji możliwe jest po dokonaniu Rejestracji i zalogowaniu się na swoim Koncie przez Użytkownika.</li>
                <li>Użytkownik ma możliwość dokonania Subskrypcji na jeden z oferowanych w ramach Serwisu pakietów dostępu do Aplikacji, w
                    ramach którego dostępne są oferowane w nim funkcjonalności:
                    <ol className="legal-list">
                        <li>„Pakiet podstawowy” oferujący możliwość sporządzenia nielimitowanych eksportów nieruchomości w w czasie ważności abonamentu w
                            ramach jednego loginu (dostępu) do aplikacji;
                        </li>
                        <li>„Pakiet biurowy” oferujący możliwość sporządzenia nielimitowanych eksportów rzutów nieruchomości dostosowanych
                            do potrzeb firmy (biura nieruchomości) w czasie ważności abonamentu w ramach pakietu loginów (dostępu) przygotowanych dla
                            firmy (biura nieruchomości);
                        </li>
                        <li>Szczegółowy opis każdego z ww. Pakietów wskazanych w lit. a) i b), w tym opis dostępnych funkcjonalności,
                            warunków korzystania z Pakietu i opcji płatności za dostęp do Aplikacji w ramach danego Pakietu, zawarty jest
                            w <a className="screen-hidden" href="https://archiplaner.pl/#cennik" target="_blank">zakładce Serwisu</a><a className="print-hidden" href="/#cennik" target="_blank">zakładce Serwisu</a>.
                        </li>
                    </ol>
                </li>
                <li>W celu dokonania Subskrypcji Użytkownik powinien wybrać właściwy pakiet dostępu do Aplikacji, za którego Subskrypcję
                    przewidziana jest określona cena brutto wyrażona w PLN (złotych
                    polskich) lub cena do uzgodnienia z Administratorem oraz kliknąć na odpowiednią ikonkę.
                </li>
                <li>Po dokonaniu Subskrypcji, Użytkownik będący Konsumentem otrzyma dostęp do aplikacji oraz informację zawierającą
                    pouczenie o przysługującym mu prawie do odstąpienia od umowy sprzedaży
                    dostępu do Aplikacji oferowanej w Serwisie oraz o utracie prawa do odstąpienia od umowy sprzedaży dostępu do Aplikacji
                    oferowanej w Serwisie, w związku ze złożonym przez Konsumenta
                    żądaniem rozpoczęcia świadczenia usługi przed upływem 14-dniowego terminu do odstąpienia od powyższej umowy. Użytkownik
                    składa oświadczenie, iż został pouczony o powyższych
                    okolicznościach.
                </li>
                <li>Następnie Użytkownik zostaje przekierowany do okna płatności, gdzie jest zobowiązany do opłacenia Subskrypcji za
                    pośrednictwem operatora płatności internetowych, oferującego
                    możliwość płacenia kartą kredytową lub przelewem bankowym.
                </li>
                <li>W momencie dokonania płatności za Subskrypcję po wybraniu właściwego pakietu Aplikacji, dochodzi do zawarcia umowy
                    między Administratorem a Użytkownikiem, której przedmiotem jest
                    sprzedaż dostępu do Aplikacji oferowanej w Serwisie zgodnie z wybranym pakietem.
                </li>
                <li>Dokonanie płatności jest każdorazowo potwierdzane informacją na adres poczty elektronicznej Użytkownika potwierdzającego
                    zakup dostępu. Po dokonaniu płatności Administrator przesyła do Użytkownika fakturę elektroniczną w rozumieniu art. 2
                    pkt. 32) ustawy o podatku od towarów i usług (Dz. U. z 2011 r. poz. 1054 ze zm.) na adres
                    poczty elektronicznej Użytkownika.
                </li>
                <li>Wykupiona przez Użytkownika Subskrypcja wyświetla się w zakładce „Subskrypcja” na Koncie.</li>
                <li>Użytkownik ma możliwość wznawiania Subskrypcji na kolejne okresy. W tym celu powinien kliknąć w ikonkę „Przedłuż
                    subskrypcje” znajdującą się w zakładce „Subskrypcja” na Koncie oraz
                    dokonać opłacenia Subskrypcji w sposób określony w ust. 6 i 7.
                </li>
                <li>Użytkownik ma możliwość zmiany pakietu Aplikacji w kierunku pakietu bardziej zaawansowanego.</li>
                <li>Opłacenie Subskrypcji przez Użytkownika powoduje (nie później niż w ciągu 24 godzin) od chwili otrzymania przez
                    Administratora potwierdzenia płatności od operatora płatności i
                    zaakceptowania rezygnacji z prawa do odstąpienia od umowy sprzedaży dostępu do Aplikacji oferowanej w Serwisie w związku
                    ze złożonym przez Konsumenta żądaniem rozpoczęcia
                    świadczenia usługi przed upływem 14-dniowego terminu do odstąpienia od powyższej umowy) umożliwienie dostępu do
                    Aplikacji w wybranym przez Użytkownika pakiecie dostępu do
                    Aplikacji. Użytkownik uzyskuje dostęp do Aplikacji archiplaner.pl/draw. Jednakże w szczególnych przypadkach, np. na
                    skutek awarii serwera, Aplikacja może zostać udostępniona
                    Użytkownikowi później niż w ciągu 24 godzin od chwili opłacenia Subskrypcji.
                </li>
                <li>Od chwili udostępnienia Aplikacji Użytkownik może z niej korzystać (z funkcjonalności zawartych w Aplikacji –
                    odpowiednim pakiecie wskazanym w ust. 3 lit. a i b), przy czym
                    udostępnienie Aplikacji przez Administratora na rzecz Użytkownika wyłącza możliwość odstąpienia od umowy sprzedaży
                    dostępu do Aplikacji oferowanej w Serwisie – na zasadach
                    określonych w Rozdziale VI.
                </li>
                <li>Dokonując Subskrypcji, Użytkownik jest upoważniony do korzystania z Aplikacji wyłącznie na własny użytek osobisty
                    Użytkownika. Subskrypcja nie oznacza przeniesienia praw ani
                    udzielenia licencji do Aplikacji ani innych wkładów (zawierających jakikolwiek element twórczy) zawartych w Aplikacji.
                    Inne, niż określone w niniejszym Regulaminie utrwalanie,
                    zwielokrotnianie, a także wprowadzanie do obrotu oraz rozpowszechnianie Aplikacji wymaga każdorazowo zgody właściciela
                    praw (najczęściej Administratora), za wyjątkiem przypadków
                    wyraźnie dozwolonych przez prawo, w tym przez ustawę o prawie autorskim i prawach pokrewnych z dnia 04.02.1994 r. (Dz.U.
                    Nr 24, poz. 83 z późn. zm.).
                </li>
                <li>Po wykupieniu Subskrypcji przez Użytkownika (wykupienie jednego z pakietów dostępu do Aplikacji oferowanych przez
                    Administratora), konkretne rzuty nieruchomości wygenerowane przez Użytkownika za pośrednictwem Aplikacji stanowią
                    własność intelektualną tego Użytkownika (o ile zawierają element twórczy), co oznacza m.in., że Użytkownik jest
                    uprawniony do komercyjnego wykorzystywania wygenerowanych przez siebie rzutów w prowadzonej działalności. Konkretne
                    rzuty nieruchomości wygenerowane przez Użytkownika za pośrednictwem Aplikacji (w jej płatnej wersji) mogą służyć zatem przygotowaniu spersonalizowanej prezentacji lub przedstawieniu kluczowych cech
                    nieruchomości w ramach oferty kierowanej do pojedynczej osoby lub do ogółu osób.
                </li>
            </ol>

            <h3>ROZDZIAŁ VI. ODSTĄPIENIE OD SUBSKRYPCJI I ZWROT PŁATNOŚCI</h3>
            <ol>
                <li>Konsumentowi przysługuje prawo do odstąpienia od dokonanej Subskrypcji. Odstąpienie od Subskrypcji następuje przez
                    złożenie oświadczenia w terminie 14 dni od dnia dokonania
                    Subskrypcji, czyli od dnia zawarcia umowy sprzedaży dostępu do Aplikacji oferowanej w Serwisie. Oświadczenie nie musi
                    zawierać uzasadnienia.
                </li>
                <li>W przypadku wyrażenia przez Konsumenta zgody na rozpoczęcie świadczenia usługi dostępu do Aplikacji oferowanej w
                    Serwisie w ramach Subskrypcji przed upływem 14-dniowego terminu do
                    odstąpienia od umowy i poinformowania Konsumenta o utracie prawa do odstępowania od umowy, prawo do odstąpienia wygasa z
                    chwilą nadania Użytkownikowi przez Administratora dostępu
                    do Aplikacji oferowanej w Serwisie w ramach Subskrypcji.
                </li>
                <li>W przypadku braku zgody Konsumenta na rozpoczęcie świadczenia usługi przed upływem okresu do odstąpienia od umowy,
                    Administrator powstrzymuje się od udostępnienia Aplikacji
                    oferowanej w Serwisie w ramach Subskrypcji do końca tego okresu. Konsument może w każdym czasie wyrazić zgodę na
                    rozpoczęcie świadczenia usługi przed upływem okresu do odstąpienia
                    od umowy. Oświadczenie jest składane za pośrednictwem poczty elektronicznej.
                </li>
                <li>Konsument może złożyć oświadczenie o odstąpieniu od umowy sprzedaży dostępu do Aplikacji oferowanej w Serwisie zawartej
                    w wyniku dokonanej Subskrypcji:
                    <ol className="legal-list">
                        <li>za pośrednictwem poczty elektronicznej kierowanej pod adres e-mail: <a href="mailto:info@archiplaner.pl">info@archiplaner.pl</a>; lub</li>
                        <li>listownie za pośrednictwem poczty, w przesyłce adresowanej do Administratora na adres: ul. Wita Stwosza 16,
                            50-148 Wrocław (o zachowaniu 14-dniowego terminu decyduje data
                            stempla pocztowego); lub
                        </li>
                        <li>na piśmie złożonym bezpośrednio w siedzibie Administratora: ul. Wita Stwosza 16, 50-148 Wrocław lub Oświadczenie
                            o odstąpieniu może zostać złożone przy użyciu wzoru formularza - Wzór odstąpienia można pobrać w formie
                            PDF - <a className="screen-hidden" href="https://archiplaner.pl/Odstapienie_od_umowy.pdf" target="_blank">kliknij aby pobrać wzór odstąpienia PDF</a><a className="print-hidden" href="/Odstapienie_od_umowy.pdf" target="_blank">kliknij aby pobrać wzór odstąpienia PDF</a>.</li>
                    </ol>
                </li>
                <li>W przypadku odstąpienia od umowy sprzedaży dostępu do Aplikacji oferowanej w Serwisie, uważa się ją za niezawartą.
                    Administrator w terminie 14 dni zwraca Konsumentowi wszystkie
                    dokonane przez niego płatności. Jeżeli Konsument uiścił płatność za Subskrypcję kartą płatniczą lub przelewem
                    elektronicznym, Administrator zwraca całą kwotę na kartę lub na numer
                    rachunku bankowego Konsumenta.
                </li>
            </ol>

            <h3>ROZDZIAŁ VII. REKLAMACJE</h3>
            <ol>
                <li>Każdy Użytkownik, w tym Konsument, może zgłaszać reklamacje dotyczące wszelkich usług wymienionych w niniejszym
                    Regulaminie (w tym wad i usterek Aplikacji). Reklamacje powinny być
                    składane wyłącznie:
                    <ol className="legal-list">
                        <li>za pośrednictwem poczty elektronicznej kierowanej pod adres e-mail: <a href="mailto:info@archiplaner.pl">info@archiplaner.pl</a>; lub</li>
                        <li>listownie za pośrednictwem poczty, w przesyłce adresowanej do Administratora na adres: ul. Wita Stwosza 16,
                            50-148 Wrocław;
                        </li>
                        <li>na piśmie złożonym bezpośrednio w siedzibie Administratora: ul. Wita Stwosza 16, 50-148 Wrocław;</li>
                    </ol>
                </li>
                <li>Reklamacja zostanie rozpatrzona w terminie 14 dni od dnia otrzymania. O rozstrzygnięciu reklamacji Administrator
                    poinformuje Użytkownika – pocztą elektroniczną – na adres e-mail, z
                    którego nadesłana została reklamacja; listownie – zgodnie z adresem podanym przez Użytkownika w reklamacji złożonej
                    listownie lub osobiście.
                </li>
            </ol>

            <h3>ROZDZIAŁ VIII. POSTANOWIENIA KOŃCOWE</h3>
            <ol>
                <li>Integralną część Regulaminu, wiążącą wszystkich Użytkowników (w tym Konsumentów),
                    stanowi Załącznik <a className="screen-hidden" href="https://archiplaner.pl/polityka-prywatnosci" target="_blank">„Polityka Prywatności”</a><a className="print-hidden" href="/polityka-prywatnosci" target="_blank">„Polityka Prywatności”</a>,
                    określający sposób przetwarzania danych pozyskiwanych
                    przez Administratora, zgodny z przepisami o ochronie danych osobowych, w tym z RODO.
                </li>
                <li>Administrator zastrzega sobie prawo do zmiany postanowień Regulaminu. Administrator zobowiązuje się udostępniać
                    nieprzerwanie informację o planowanej zmianie Regulaminu w ciągu 14
                    dni przed planowaną zmianą na stronie internetowej Serwisu. Zmiana wchodzi w życie po upływie 14 dni od dnia
                    zamieszczenia informacji w ww. trybie.
                </li>
                <li>Zmiana Regulaminu nie dotyczy Subskrypcji dokonanych przed wejściem w życie nowego Regulaminu.</li>
                <li>We wszystkich sprawach nieuregulowanych w niniejszym Regulaminie zastosowanie mają przepisy powszechnie obowiązującego
                    prawa polskiego, a w szczególności przepisy kodeksu
                    cywilnego, ustawy o świadczeniu usług drogą elektroniczną oraz ustawy o prawie autorskim i prawach pokrewnych.
                </li>
                <li>Wszelkie spory pomiędzy stronami mogą być rozstrzygane przez właściwy sąd powszechny.</li>
                <li>Spory wynikające z niniejszego Regulaminu mogą zostać rozstrzygnięte za pomocą pozasądowych sposobów rozpatrywania
                    reklamacji i dochodzenia roszczeń, w szczególności spory z
                    Konsumentami przez stałe konsumenckie sądy polubowne przy wojewódzkich inspektorach Inspekcji Handlowej, w postępowaniu
                    w sprawach mediacji realizowanych przez Inspekcję Handlową
                    oraz w postępowaniu prowadzonym przez Europejskie Centrum Konsumenckie w Polsce.
                </li>

                <li>Regulamin można pobrać w formacie
                    PDF – <a className="screen-hidden" href="https://archiplaner.pl/Regulamin_04.12.2021.pdf" target="_blank">kliknij by pobrać PDF</a><a className="print-hidden" href="/Regulamin_04.12.2021.pdf" target="_blank">kliknij by pobrać PDF</a>.</li>
            </ol>
            Niniejszy Regulamin wchodzi w życie z dniem 4.12.2021 r.
        </Container>
    </>
);