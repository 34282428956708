//@flow
import React, {useCallback, useState} from 'react';
import {Form} from "react-bootstrap";
import {useHistory} from 'react-router-dom'
import {DefaultLink} from "./lib/Layout";
import {isUE, ueEvent} from "./lib/UserCom";

export const CollapsibleText = ({ children, label }: { label: string }) => {
    let [ open, setOpen ] = useState(false);
    return <div className={"collapsible-text "+(open?"open":"closed")}>
        <a href="#"
        onClick={(e) => {
            setOpen(!open)
            e.preventDefault();
        }}
        >{label}</a>
        <div>{children}</div>
    </div>
};

export const FormError = ({ message }) => {
    if(typeof(message)!=='string' || message.length===0) return null;
    return <Form.Control.Feedback type="invalid">{message}</Form.Control.Feedback>;
};

export const LandingLink = ({ to, event, onClick, ...props }: { to: string }) => {
    const history=useHistory();
    const ueCallback=useCallback((e: SyntheticEvent) => {
        if(onClick) onClick(e);
        if(e.isDefaultPrevented()) return;

        e.preventDefault();
        history.push(to);

        if(!event || !isUE()) {
            return;
        }
        ueEvent(event).finally();
    }, [ to, event ])

    if(to.startsWith("/app") || to.startsWith("/draw")) {   // wyjście poza aplikację
        return <DefaultLink to={to} event={event} {...props}/>  // Domyślny Link ma obsługę do wszystkiego
        // return <a href={to} {...props}></a>;
    }
    return <a href={to} {...props} onClick={ueCallback}/>   // History + userEvent.
    // return <Link to={to} {...props} onClick={(e) => {
    //
    // }}/>;
}