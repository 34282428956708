//@flow
import React from 'react';
import {Head} from "react-static";
import {Container} from "react-bootstrap";

export default () => (
    <>
        <Head>
            <title>Nie znaleziono strony</title>
        </Head>
        <Container className="p-section">
            <h1 className="text-center pb-3">Nie znaleziono strony!</h1>
        </Container>
    </>
)
