//@flow

import {useRecoilValueLoadable} from "recoil";
import {userAtom} from "./UserSession";
import {useEffect} from "react";

let initialized=false;
const facebookPixelCode='945708689606827';

/**
 * Kod skopiowany z kodu Facebook, który należy dodać do sekcji Head.
 * Kod ten dodaje funkcję f.fbq
 */
function initialize() {
    if(initialized || typeof(window)==='undefined') return;
    /* eslint-disable */
    !(function(f,b,e,v,n,t,s) {
        if (f.fbq) return;
        n = f.fbq = function () {
            n.callMethod ?
                n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s)
    })(window,document,'script', 'https://connect.facebook.net/en_US/fbevents.js');
}

export function useFacebookPixel() {
    const { contents, state }=useRecoilValueLoadable(userAtom);
    const pathName=typeof(window)==='undefined'?"":(window.location.pathname+window.location.search);

    useEffect(() => {
        if(state==="loading" || typeof(window)==='undefined') return;
        initialize();
        window.fbq('init', facebookPixelCode, {
            external_id: contents?contents.id:undefined,
            em: contents?contents.email:undefined,
        });
    }, [ state ]);

    // useEffect(() => {
    //     if(typeof(window)!=='undefined') {
    //         if (contents) {
    //             window.gtag({userId: contents.id});
    //         } else {
    //             window.gtag({userId: null});
    //         }
    //     }
    // }, [ contents])
    useEffect(() => {
        if(state==="loading" || typeof(window)==='undefined') return;
        initialize();
        window.fbq('track', 'PageView');
    }, [ state, pathName ]);
}