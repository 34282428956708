//@flow
import React, {useCallback, useEffect, useState} from 'react';
import {Head, useSiteData} from "react-static";
import {Button, Col, Container, Form, Modal, Row} from "react-bootstrap";
import {CollapsibleText, FormError} from "../Components";
import Packet, {PacketAction, PacketFooter, PacketPrice, PriceMode} from "../lib/Packets";
import {SiteDataMeta} from "../static-api";
import type {LanguageData} from "../LangType";
import {formatString} from "../lib/Language";
import type {PaymentMode} from "../lib/Packets";
import {publicApi} from "../lib/Network";
import {Icon} from "../lib/Icon";
import {useRecoilValueLoadable} from "recoil";
import {userAtom} from "../lib/UserSession";

/**
 * Wyodrębniony formularz kontaktowy
 */
const ContactForm = ({ visible, onDone }: { visible: boolean, onDone: () => void }) => {
    const [ email, setEmail ]= useState("");
    const [ name, setName ]= useState("");
    const [ phone, setPhone] = useState("");
    const [ users, setUsers ]= useState("");
    const [ exports, setExports ]= useState("");
    const [ time, setTime ]= useState("");
    const [ message, setMessage] = useState("");
    const [ terms, setTerms ]= useState(false);
    const [ errors, setErrors ]= useState({});

    return <Modal show={visible}>
        <Modal.Header>
            <Modal.Title>Kontakt handlowy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                <Form.Group controlId="email">
                    <Form.Label>Adres e-mail</Form.Label>
                    <Form.Control type="email" placeholder="Adres e-mail"
                                  isInvalid={errors.email?true:false}
                                  value={email} onChange={e => setEmail(e.target.value)}
                    />
                    <FormError message={errors.email}/>
                </Form.Group>
                <Form.Group controlId="name">
                    <Form.Label>Nazwa firmy/imię nazwisko</Form.Label>
                    <Form.Control type="text" placeholder="Nazwa"
                                  isInvalid={errors.name?true:false}
                                  value={name} onChange={e => setName(e.target.value)}
                    />
                    <FormError message={errors.name}/>
                </Form.Group>
                <Form.Group controlId="phone">
                    <Form.Label>Numer telefonu</Form.Label>
                    <Form.Control type="text" placeholder="Nr telefonu"
                                  isInvalid={errors.phone?true:false}
                                  value={phone} onChange={e => setPhone(e.target.value)}
                    />
                    <FormError message={errors.phone}/>
                </Form.Group>
                <Form.Group controlId="users">
                    <Form.Label>Liczba kont/użytkowników</Form.Label>
                    <Form.Control type="number" placeholder="Liczba kont/użytkowników"
                                  value={users} onChange={e => setUsers(e.target.value)}
                                  isInvalid={errors.users?true:false}
                    />
                    <FormError message={errors.users}/>
                </Form.Group>
                <Form.Group controlId="exports">
                    <Form.Label>Liczba rzutów/eksportów</Form.Label>
                    <Form.Control type="number" placeholder="Liczba rzutów/eksportów"
                                  value={exports} onChange={e => setExports(e.target.value)}
                                  isInvalid={errors.exports?true:false}
                    />
                    <FormError message={errors.exports}/>

                </Form.Group>
                <Form.Group controlId="time">
                    <Form.Label>Czas ważności (liczba miesięcy)</Form.Label>
                    <Form.Control type="number" placeholder="Czas ważności (liczba miesięcy)"
                                  value={time} onChange={e => setTime(e.target.value)}
                                  isInvalid={errors.time?true:false}
                    />
                    <FormError message={errors.time}/>
                </Form.Group>
                <Form.Group controlId="other">
                    <Form.Label>Dodatkowe informacje</Form.Label>
                    <Form.Control as="textarea" rows={4} placeholder="Opcjonalna wiadomość dotycząca zamówienia"
                                  value={message} onChange={e => setMessage(e.target.value)}
                    />
                </Form.Group>
                <Form.Group controlId="term">
                    <Form.Check
                        custom
                        type="checkbox"
                        id="terms"
                    >
                        <Form.Check.Input
                            type="checkbox"
                            checked={terms} onChange={e => setTerms(e.target.checked)}
                            isInvalid={!!errors.terms}
                        />
                        <Form.Check.Label>Wyrażam zgodę na przetwarzanie przez administratora moich danych osobowych,
                            przez firmę Archiplaner sp. z o.o., danych wysłanych w formularzu kontaktowym w celu
                            przygotowania oferty handlowej.</Form.Check.Label>
                        <FormError message={errors.terms}/>
                    </Form.Check>
                </Form.Group>
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => onDone()}>Zamknij</Button>
            <Button variant="primary" onClick={() => {
                publicApi.contact({
                    email: email,
                    name: name,
                    phone: phone,
                    exports: exports,
                    users: users,
                    time: time,
                    message: message,
                    terms: terms,
                }).then((err) => {
                    if(err) {
                        console.log("Got errors: ", err);
                        setErrors(err.fields);
                    } else {
                        setErrors({});
                        setEmail(""); setExports(""); setUsers(""); setTime("");
                        setName(""); setPhone("");
                        setMessage(""); setTerms(false);
                        onDone();
                        window.alert("Wiadomość została wysłana. Niebawem się ktoś z Państwem skontaktuje.");
                    }
                })
            }}>Wyślij zapytanie</Button>
        </Modal.Footer>
    </Modal>
}

/**
 * Część strony z odtwarzaczem wideo
 */
const VideoPart = () => {
    const [ video, setVideo ] = useState(0);
    const [ videoWidth, setVideoWidth ] = useState(() => {
        if(typeof(window)==='undefined') return 1220;
        else Math.min(window.innerWidth-60, 380)
    });
    useEffect(() => {
        if(typeof(window)==='undefined') return;
        if(video!==0) return;
        const h=window.setTimeout(() => {
            setVideo(2)
        }, 2500);
        return () => window.clearTimeout(h);
    }, [ video ]);

    useEffect(() => {
        if(typeof(window)==='undefined') return;
        const handleResize=() => {
            setVideoWidth(Math.min(window.innerWidth-60, 380));
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [ setVideoWidth ]);
    const videoHeight=Math.floor(videoWidth*0.574468085106383);

    return <Col md={6} className="p-section">
        <div className="position-relative">
            <img
                loading="lazy"
                className="monitor-image"
                src="/images/intro-1.jpg"
                width={454} height={427}
                style={{ maxWidth: '100%' }} alt="Zrzut z ekranu"
            />
            <div style={{
                position: "absolute",
                top: "11px",
                left: "15px",
                width: videoWidth+"px",
                height: videoHeight+"px"
            }}>
                {video?<iframe
                    title="Video prezentacja"
                    src={"https://player.vimeo.com/video/502306251"+(video===1?"?autoplay=1":"")}
                    width={videoWidth} height={videoHeight}
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
                />:<div
                    className="play-button"
                    onClick={() => setVideo(1)}
                ><span><i/></span></div>}

            </div>
        </div>
    </Col>;
}

/**
 * Fragment z cennikami
 */
const PricesPart = () => {
    const [ priceMode, setPriceMode ] = useState<PaymentMode>("month");
    const changePriceMode=useCallback((e: SyntheticEvent) => setPriceMode(e.target.value));
    const { lang }=useSiteData<SiteDataMeta>();
    const msgs:LanguageData=lang.pl;
    const { contents, state }=useRecoilValueLoadable(userAtom);

    /** Czy użytkownik jest zalogowany */
    const logged=(state==="hasValue") && contents!==null;

    const buttonMessage=logged?msgs.commons.packetActionBuy:msgs.commons.packetActionCreate;

    return <Container fluid="xl" className="p-section" id="cennik">
        <div className="text-center p-def">
            <h3 className="uppercase">Cennik</h3>
        </div>
        <Row>
            <Col md={4} className="pr-md-3 pb-4">
                <Packet type="Free">
                    <PacketPrice>{msgs.commons.packetPriceFree}</PacketPrice>
                    <PacketFooter>{msgs.commons.packetPriceOneFree}</PacketFooter>
                    <PacketAction data-prefetch="false" href={logged?"/draw/":"/app/register"}>{logged?msgs.commons.packetActionDraw:msgs.commons.packetActionCreate}</PacketAction>
                </Packet>
            </Col>
            <Col md={4} className="pr-md-3 pb-4">
                <Packet type="Basic">
                    <PacketPrice><span>{priceMode==="month"?"29,00 PLN":"261,00 PLN"}</span><PriceMode value={priceMode} onChange={changePriceMode}/></PacketPrice>
                    <PacketFooter>{priceMode==="month"?null:<><s>348,00 PLN</s> {formatString(msgs.commons.packetPriceDiscount, 25)}</>}</PacketFooter>
                    <PacketAction data-prefetch="false" href={(logged?"/app/packet":"/app/register")+"/basic_"+priceMode}>{buttonMessage}</PacketAction>
                </Packet>
            </Col>
            <Col md={4} className="pl-md-3 pb-4">
                <Packet type="Company">
                    <PacketPrice><span>{priceMode==="month"?"99,00 PLN":"891,00 PLN"}</span><PriceMode value={priceMode} onChange={changePriceMode}/></PacketPrice>
                    <PacketFooter>{priceMode==="month"?<b>&nbsp;</b>:<><s>1188,00 PLN</s> {formatString(msgs.commons.packetPriceDiscount, 25)}</>}</PacketFooter>
                    <PacketAction data-prefetch="false" href={(logged?"/app/packet":"/app/register")+"/company_"+priceMode}>{buttonMessage}</PacketAction>
                </Packet>
            </Col>
        </Row>
    </Container>

}

export default () => {
    const [ salesContact, setSalesContact ] = useState(false);

    return (
        <>
            <Head>
                <title>Program do rysowania rzutów mieszkań dostępny online</title>
                <meta name="description" content="Narysuj swój pierwszy plan mieszkania w programie do rzutów dostępnym online. Stwórz nowy rzut mieszkania, domu czy lokalu w Archiplaner."/>
            </Head>
            <Container fluid="xl" className="landing">
                <Row>
                    <Col md={6} className="d-flex flex-row align-items-center p-section">
                        <div className="mr-md-5">
                            <h1>Najprostsza aplikacja do rzutów mieszkań online na rynku!</h1>
                            <p>Stwórz <b>szybko i łatwo</b> plan Twojego mieszkania online - w niecałe 3 minuty. Przygotuj profesjonalny rysunek techniczny i przykuj uwagę nowych klientów. Tylko 3 kroki dzielą Ciebie od własnego rzutu.</p>
                            <Button data-prefetch="false" href="/draw">Wypróbuj za darmo</Button>
                        </div>
                    </Col>
                    <VideoPart/>
                </Row>
            </Container>
            <div className="bg-white position-relative">
                <div className="section-image-left" style={{ backgroundImage: "url('/images/benefits-l.jpg')" }}/>
                <div className="section-image-right"  style={{ backgroundImage: "url('/images/benefits-r.jpg')" }}/>
                <Container className="mw-md p-section mx-auto">
                    <Row className="pb-5">
                        <Col md={1}/>
                        <Col md={10} className="text-center">
                            <h2 className="uppercase">Korzyści</h2>
                            <p>Stwórz skuteczną ofertę, która przyciągnie uwagę klientów</p>
                        </Col>
                        <Col md={1}/>
                    </Row>
                    <Row>
                        <Col md={4} className="text-center pb-5">
                            <Icon.Favourite className="svg-icon"/>
                            <p>Wyróżnij się na tle innych ofert</p>
                        </Col>
                        <Col md={4} className="text-center pb-5">
                            <Icon.SketchBig className="svg-icon"/>
                            <p>Przygotuj spersonalizowaną prezentację</p>
                        </Col>
                        <Col md={4} className="text-center pb-5">
                            <Icon.SafeHome className="svg-icon"/>
                            <p>Przedstaw kluczowe cechy nieruchomości</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}/>
                        <Col md={4} className="text-center pb-5">
                            <Icon.LocationOk className="svg-icon"/>
                            <p>Zwiększ wiarygodność oferty</p>
                        </Col>
                        <Col md={4} className="text-center pb-5">
                            <Icon.DocumentHome className="svg-icon"/>
                            <p>Zadbaj o czytelność ogłoszenia</p>
                        </Col>
                        <Col md={2}/>
                    </Row>
                </Container>
            </div>
            <Container fluid="xl" className="p-section">
                <div className="text-center pb-5">
                    <h3 className="uppercase">Jak to działa?</h3>
                    <p>Przygotuj rzut w 3 prostych krokach</p>
                </div>
                <Row>
                    <Col md={4} className="text-center">
                        <div className="step-icon mx-auto">1</div>
                        <img className="landing-step"
                             loading="lazy"
                             src="/images/example/1-thumb.png" width={238} height={208}
                             srcSet="/images/example/1-thumb.png w238, /images/example/1-thumb.png_x2 w468"
                             alt="Rozkład pomieszczeń"/>
                        <p>Naszkicuj rozkład pomieszczeń</p>
                    </Col>
                    <Col md={4} className="text-center">
                        <div className="step-icon mx-auto">2</div>
                        <img className="landing-step"
                             loading="lazy"
                             src="/images/example/2-thumb.png" width={234} height={208}
                             srcSet="/images/example/2-thumb.png w234, /images/example/2-thumb.png_x2 w468"
                             alt="Elementy wyposażenia"/>
                        <p>Dodaj dodatkowe elementy wyposażenia</p>
                    </Col>
                    <Col md={4} className="text-center">
                        <div className="step-icon mx-auto">3</div>
                        <img className="landing-step"
                             loading="lazy"
                             src="/images/example/3-thumb.png" width={346} height={208}
                             srcSet="/images/example/3-thumb.png w346, /images/example/3-thumb.png_x2 w692"
                             alt="Eksport"/>
                        <p>Eksportuj rzut i dodaj plik do ogłoszenia wybierając format</p>
                    </Col>
                </Row>
            </Container>
            <div className="bg-white position-relative">
                <div className="section-image-left" style={{ backgroundImage: "url('/images/functions-l.jpg')" }}/>
                <div className="section-image-right"  style={{ backgroundImage: "url('/images/functions-r.jpg')" }}/>
                <Container className="mw-md p-section mx-auto">
                    <Row className="p-def">
                        <Col md={1}/>
                        <Col md={10} className="text-center">
                            <h2 className="uppercase">Funkcjonalności</h2>
                            <p>Przemyślane rozwiązania i efektywność Archiplanera</p>
                        </Col>
                        <Col md={1}/>
                    </Row>
                    <Row>
                        <Col md={4} className="text-center p-def">
                            <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" stroke="#464d57" fill="none" strokeMiterlimit="10">
                                <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                    <path d="M45 57l12-12M22 10L10 22"/>
                                    <path d="M19 31L3 15 15 3l16 16m14 14l12 12 2 14-14-2-12-12"/>
                                </g>
                                <g stroke="#1f788a">
                                    <path d="M3 47L47 3l14 14-44 44z" strokeLinejoin="round" strokeWidth="2"/>
                                    <path d="M25 25l6 6m-12 0l4 4m14-22l6 6m-12 0l4 4M13 37l6 6" strokeLinejoin="round" strokeWidth="2" strokeLinecap="round"/>
                                </g>
                            </svg>
                            <p>Szybki dostęp do roboczej wersji rzutu</p>
                        </Col>
                        <Col md={4} className="text-center p-def">
                            <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
                                <style>{`.B{strokeMiterlimit:10} .C{strokeLinejoin:round} .D{strokeWidth:2}.E{strokeLinecap:round}`}</style>
                                <g stroke="#464d57" fill="none" className="B C D">
                                    <g stroke="#1f788a">
                                        <path d="M57,35V30a4,4,0,0,0-4-4H31a4,4,0,0,0-4,4v5" className="E"/>
                                        <path d="M52 45v4H32v-4a5 5 0 0 0-5-5h0a5 5 0 0 0-5 5v13h40V45a5 5 0 0 0-5-5h0a5 5 0 0 0-5 5z"/>
                                        <path d="M26 58v4m32-4v4" className="E"/>
                                    </g>
                                    <path d="M12 62V24" className="E"/>
                                    <path d="M17 3H7L2 17v2h20v-2L17 3z"/>
                                    <path d="M18 62H6" className="E"/>
                                </g>
                            </svg>
                            <p>Wyposażenie dodane jednym kliknięciem</p>
                        </Col>
                        <Col md={4} className="text-center p-def">
                            <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" stroke="#464d57" fill="none" strokeMiterlimit="10" strokeLinejoin="round"
                                 strokeWidth="2">
                                <path stroke="#1f788a" d="M58 59H6a4 4 0 0 1-4-4V5h22l6 8h32v42a4 4 0 0 1-4 4z"/>
                                <path d="M32 23.636l4.017 8.14L45 33.08l-6.5 6.336 1.534 8.947L32 44.14l-8.034 4.224 1.534-8.947L19 33.08l8.983-1.305z"/>
                            </svg>
                            <p>Własna biblioteka rzutów i szablonów od ręki</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}/>
                        <Col md={4} className="text-center p-def">
                            <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
                                <style>{`.B{stroke - miterlimit:10}.C{stroke - linejoin:round}.D{stroke - width:2}`}</style>
                                <g stroke="#464d57" fill="none" className="B C D">
                                    <path d="M12 52h40M12 10h40" stroke="#1f788a" strokeLinecap="round"/>
                                    <path d="M32 20l-10 8v13h7v-7h6v7h7V28l-10-8z"/>
                                    <rect x="12" y="2" width="40" height="60" rx="5" stroke="#1f788a"/>
                                </g>
                            </svg>
                            <p>Stwórz własny widok rzutów</p>
                        </Col>
                        <Col md={4} className="text-center p-def">
                            <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" stroke="#464d57" fill="none">
                                <g stroke="#1f788a" strokeMiterlimit="10" strokeLinejoin="round" strokeWidth="2">
                                    <path d="M44 2v12h12" strokeLinecap="round"/>
                                    <path d="M44 2H8v60h48V14z"/>
                                </g>
                                <circle cx="32" cy="32" r="3" strokeLinejoin="round" strokeWidth="2"/>
                                <path strokeMiterlimit="10"
                                      d="M41 32c0-.44-.042-.87-.103-1.293l3.362-3.475-2-3.464-4.686 1.173c-.677-.535-1.432-.974-2.246-1.298L34 19h-4l-1.326 4.643c-.814.324-1.57.763-2.246 1.298l-4.686-1.173-2 3.464 3.362 3.475A8.89 8.89 0 0 0 23 32c0 .44.042.87.103 1.293l-3.362 3.475 2 3.464 4.686-1.173c.677.535 1.432.974 2.246 1.298L30 45h4l1.326-4.643c.814-.324 1.57-.763 2.246-1.298l4.686 1.173 2-3.464-3.362-3.475A8.89 8.89 0 0 0 41 32z"
                                      strokeLinejoin="round" strokeWidth="2"/>
                            </svg>
                            <p>Odpowiedni format rzutu na portale</p>
                        </Col>
                        <Col md={2}/>
                    </Row>
                </Container>
            </div>
            <PricesPart/>
            <Container fluid="xl" className="pb-5">
                <div className="text-center p-def">
                    <h3 className="uppercase">FAQ</h3>
                </div>
                <Row>
                    <Col md={6}>
                        <CollapsibleText label="Jak założyć konto w Archiplaner?">
                            Wystarczy, że zarejestrujesz się na naszej platformie <a data-prefetch="false" href="/app/register/">kliknij tutaj</a>.
                        </CollapsibleText>
                        <CollapsibleText label="Czy Archiplaner jest darmowym programem do tworzenia rzutów?">
                            W darmowej wersji programu do rzutów Archiplaner mamy dostęp do wszystkich funkcji podstawowego konta poza eksportami bez znaków wodnych. To znaczy, że możemy tworzyć rzuty mieszkań do woli bez ograniczeń na własny prywatny użytek (ze znakiem wodnym).
                        </CollapsibleText>
                        <CollapsibleText label="Czy muszę instalować aplikację Archiplaner na swoim komputerze??">
                            Program do rzutów Archiplaner jest dostępny online i możemy z niego korzystać z dowolnej przeglądarki internetowej w każdym miejscu z dostępem do Internetu.
                        </CollapsibleText>
                    </Col>
                    <Col md={6}>
                        <CollapsibleText label="Co zyskam dzięki płatnej wersji Archiplaner?">
                            Wykupienie pakietu umożliwia pobranie i zapisanie plików bez znaku wodnego w Archiplaner. To rozwiązanie przeznaczone dla agencji nieruchomości do wykorzystania w celach komercyjnych, czyli publikacji obrazów na portalach wraz z ogłoszeniami.
                        </CollapsibleText>
                        <CollapsibleText label="Jak stworzyć nowy rzut mieszkania w Archiplaner?">
                            Stworzenie nowego rzutu mieszkania jest proste i składa się z 3 kroków. Najpierw dodajemy ściany, następnie drzwi, okna i obiekty meblowe. Następnie generujemy stworzony rzut. Dowiedz się więcej jak stworzyć nowy rzut <a href="/nowy-rzut-mieszkania-online/" target="blank">tutaj</a>.
                        </CollapsibleText>
                    </Col>
                </Row>
            </Container>
            <ContactForm visible={salesContact} onDone={() => setSalesContact(false)}/>
        </>
    )
}