import React from 'react';
import {Head} from "react-static";
import {Container} from "react-bootstrap";

export default () => (
    <>
        <Head>
            <title>Polityka prywatności</title>
            <meta name="description" content="Narysuj swój pierwszy plan mieszkania w programie do rzutów dostępnym online. Stwórz nowy rzut mieszkania, domu czy lokalu w Archiplaner."/>
        </Head>
        <Container className="p-section">
            <h1 className="text-center pb-3">Polityka prywatności serwisu internetowego <a href="https://archiplaner.pl">Archiplaner.pl</a></h1>
            <p><b>Szanowny Użytkowniku,</b></p>
            <p>Witamy Ciebie w Polityce Prywatności Serwisu <a href="https://archiplaner.pl">Archiplaner.pl</a></p>
            <p><b>Administrator Serwisu</b>, tj. <b>Archiplaner Sp.zo.o.</b> z siedzibą we Wrocławiu, przy ul. Wita stwosza 16, zarejestrowana w rejestrze przedsiębiorców Krajowego Rejestru Sądowego
                prowadzonym przez Sąd Rejonowy dla Wrocławia-Fabrycznej we Wrocławiu VI Wydział Gospodarczy KRS. pod nr KRS 0000880124 , kapitał zakładowy 5.000 zł wpłacony w całości,
                NIP: 8971887837, REGON 387999742; dane do kontaktu: e-mail: <a href="mailto:info@archiplaner.pl">info@archiplaner.pl</a>;, szanuje Twoją prywatność i chroni Twoje dane osobowe,które przekażesz w związku z korzystaniem z Serwisu.</p>

            <p>Zapoznając się z niniejszą polityką prywatności, uzyskasz informację odnośnie tego, w jaki sposób przetwarzane są Twoje dane osobowe, w czasie gdy odwiedzasz stronę internetową <a
                href="https://archiplaner.pl">Archiplaner.pl</a>, rejestrujesz się w Serwisie <a href="https://archiplaner.pl">Archiplaner.pl</a> oraz gdy korzystasz z funkcjonalności dostępnych w
                ramach
                Serwisu, w tym gdy dokonujesz Subskrypcji, a więc zakupu dostępu do Aplikacji oferowanej w Serwisie.</p>
            <p>Przez <b>przetwarzanie danych osobowych</b> należy rozumieć jakiekolwiek operacje wykonywane na danych osobowych, takie jak zbieranie, utrwalanie, przechowywanie, opracowywanie,
                zmienianie,
                udostępnianie i usuwanie w formie tradycyjnej oraz w systemach informatycznych.</p>
            <p>Pojęcia używane w niniejszej polityce prywatności, w tym w szczególności pojęcia Administratora, Serwisu, Użytkownika, Rejestracji, Konta, Subskrypcji, Aplikacji oraz Konsumenta są
                tożsame
                z pojęciami zdefiniowanymi w Regulaminie Serwisu <a href="https://archiplaner.pl">Archiplaner.pl</a>, dostępnym pod adresem <a href="/regulamin">https://archiplaner.pl/regulamin</a>.
            </p>
            <p>Niniejsza polityka prywatności została opracowana zgodnie z przepisami o ochronie danych osobowych, uwzględniając w szczególności wymagania względem administratorów danych osobowych
                stawiane przez Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w
                sprawie
                swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (dalej: „<b>RODO</b>”).</p>

            <h3>Kto jest Administratorem danych osobowych Użytkowników Serwisu?</h3>
            <p><b>Administratorem</b> Twoich danych osobowych jako Użytkownika Serwisu jest <b>Archiplaner Sp.zo.o.</b> z siedzibą we Wrocławiu, przy ul. Wita stwosza 16, zarejestrowana w rejestrze przedsiębiorców Krajowego Rejestru Sądowego
                prowadzonym przez Sąd Rejonowy dla Wrocławia-Fabrycznej we Wrocławiu VI Wydział Gospodarczy KRS. pod nr KRS 0000880124 , kapitał zakładowy 5.000 zł wpłacony w całości,
                NIP: 8971887837, REGON 387999742; dane do kontaktu: e-mail: <a href="mailto:info@archiplaner.pl">info@archiplaner.pl</a>; (dalej także jako: „<b>Administrator</b>”).</p>
            <p>Możesz skontaktować się z Administratorem Twoich danych osobowych:</p>
            <ol className="legal-list">
                <li>w formie pisemnej pod adresem ul. Wita Stwosza 16, 50-148 Wrocław,</li>
                <li>w formie telefonicznej pod numerem telefonu +48535167580,</li>
                <li>w formie elektronicznej pod adresem poczty elektronicznej e-mail info@archiplaner.pl.</li>
            </ol>
            <p>W trosce o zwiększenie bezpieczeństwa Twoich danych osobowych, wyznaczyliśmy Inspektora Ochrony Danych, kontakt z nim możliwy jest pod adresem mailowym info@archiplaner.pl.</p>
            <p>Jeśli masz jakiekolwiek pytania dotyczące niniejszej polityki prywatności i ochrony Twoich danych osobowych, skontaktuj się z naszym Inspektorem Ochrony Danych pod ww. adresem
                mailowym.</p>

            <h3>Jakie dane o Tobie, Użytkowniku, są zbierane?</h3>
            <p>Dane zbierane od Ciebie, zależą od tego, czy dokonałeś Rejestracji, tj. założenia Konta w Serwisie.</p>
            <p>Podczas Twojej wizyty na stronie internetowej naszego Serwisu, automatycznie zbierane są dane dotyczące Twojej wizyty, np. Twój adres IP, nazwa domeny, typ przeglądarki, typ systemu
                operacyjnego.</p>
            <p>Abyś mógł jako Użytkownik skorzystać z funkcjonalności Serwisu opisanych w Regulaminie, w tym przede wszystkim dokonać Subskrypcji i dzięki temu uzyskać dostęp do Aplikacji do
                generowania
                (tworzenia) rzutów nieruchomości przeznaczonej dla agentów nieruchomości (opisanej w Regulaminie i na stronie Serwisu), powinieneś dokonać Rejestracji, czyli założenia Konta w
                Serwisie.
                Rejestracja jest równoznaczna z zawarciem Umowy pomiędzy Tobą (jako Użytkownikiem) a Administratorem Serwisu. Podczas Rejestracji zostaniesz zapytany o aktualny adres e-mail, login
                (nazwę
                Użytkownika) oraz hasło. Dopiero po podaniu tych danych możliwa jest Rejestracja w Serwisie.</p>
            <p>Ponadto na swoim Koncie masz możliwość podania następujących danych osobowych: imienia i nazwiska, telefonu, adresu poczty elektronicznej (e-mail), biura w ramach którego wykonujesz
                czynności w zakresie pośrednictwa w obrocie nieruchomościami, ponadto lokalizacji (miasta).</p>
            <p>W sytuacji dokonania przez Ciebie Subskrypcji, czyli zakupu dostępu do Aplikacji oferowanej w Serwisie, konieczne będzie podanie przez Ciebie co najmniej imienia i nazwiska oraz adresu
                e-mail. Ponadto w przypadku, gdy zażyczyłeś sobie wystawienia faktury, przetwarzany także będzie Twój adres zamieszkania i ewentualnie numer NIP / PESEL / REGON.</p>
            <p>Firma obsługująca dla nas płatności może dodatkowo poprosić o inne dane osobowe, takie jak adres zamieszkania oraz numer rachunku bankowego.</p>
            <p>Gdy skontaktujesz się z nami za pośrednictwem poczty elektronicznej, przekażesz nam swoje dane kontaktowe w postaci adresu e-mail, natomiast w sytuacji kontaktu telefonicznego,
                przekazane
                zostaną Twoje dane kontaktowe w postaci numeru telefonu.</p>

            <h3>W jakich celach przetwarzane są Twoje dane osobowe?</h3>
            <p>Twoje dane podane przy Rejestracji przetwarzane są w celu wykonania Umowy o świadczenie usług drogą elektroniczną zawieranej po założeniu Konta w Serwisie. Dane zebrane w trakcie
                Rejestracji są niezbędne do zawarcia Umowy i jej wykonania, do tego byś mógł korzystać z funkcjonalności znajdujących się w Serwisie, w tym przede wszystkim dokonać Subskrypcji i
                uzyskać
                dostęp do Aplikacji. Podstawą prawną przetwarzania Twoich danych osobowych jako Użytkownika w tym celu jest <b>art. 6 ust. 1 lit. b RODO</b>. Podanie danych osobowych takich jak adres
                e-mail,
                login i hasło jest warunkiem zawarcia Umowy i jej realizacji. Brak podania danych osobowych może spowodować niemożność zawarcia Umowy lub jej realizacji w całości lub części. Podanie
                pozostałych danych osobowych jest dobrowolne z wyjątkiem podania danych osobowych niezbędnych do sprzedaży dostępu do Aplikacji (do dokonania Subskrypcji).</p>
            <p>Podczas Twojej wizyty na naszej stronie internetowej, automatycznie zbierane są dane dotyczące Twojej wizyty, np. Twój adres IP, a podstawą prawną przetwarzania tych danych osobowych
                jest
                <b>art. 6 ust. 1 lit. c RODO</b> (przetwarzanie jest niezbędne do wykonania obowiązku prawnego).</p>
            <p>Dane zbierane w trakcie korespondencji pomiędzy Tobą a Administratorem są wykorzystywane wyłącznie w celu udzielenia odpowiedzi na Twoje zapytanie czy poinformowania Cię o zmianach w
                Serwisie lub o istotnych zmianach Umowy.</p>
            <p>Twoja dane muszą być także przetwarzane w celach rozliczenia oraz prowadzenia sprawozdawczości finansowej – wynika to z obowiązków, jakie nakładają na nas przepisy prawa. Podstawą
                prawną
                przetwarzania tych danych osobowych jest <b>art. 6 ust. 1 lit. c RODO</b>.</p>
            <p>Twoje dane są ponadto przetwarzane w celach analitycznych i statystycznych – podstawą prawną przetwarzania jest uzasadniony interes Administratora (<b>art. 6 ust. 1 lit. f RODO</b>),
                polegający
                na prowadzeniu analiz aktywności Użytkowników w Serwisie, w celu doskonalenia stosowanych funkcjonalności.</p>
            <p>Tylko w sytuacji wyrażenia przez Ciebie wyraźnej i odrębnej zgody, Twoje dane Administrator będzie przetwarzać w celach marketingowych i promocyjnych dotyczących usług oferowanych przez
                Administratora lub podmioty powiązane z Administratorem (podstawą prawną przetwarzania Twoich danych osobowych będzie w tym zakresie Twoja zgoda oraz <b>art. 6 ust. 1 lit. a RODO</b>).
            </p>
            <p>W momencie podania przez Ciebie także innych danych identyfikacyjnych (np. imienia i nazwiska, numeru PESEL) i danych kontaktowych (np. adresu zamieszkania) oraz w sytuacji zaistnienia
                sporu pomiędzy Tobą jako Użytkownikiem a nami jako Administratorem Serwisu, Twoje dane mogą być przetwarzane w celu dochodzenia roszczeń Administratora. Podstawą prawną przetwarzania
                Twoich danych osobowych jako Użytkownika w tym celu jest <b>art. 6 ust. 1 lit. f RODO</b>.</p>

            <h3>Komu mogą być przekazane Twoje dane osobowe?</h3>
            <p>Twoje dane będą przekazywane pracownikom Administratora i osobom działającym na zlecenie Administratora w celu wykonania umowy zawartej pomiędzy Tobą jako Użytkownikiem a
                Administratorem
                (umowy sprzedaży dostępu do Aplikacji po dokonaniu Subskrypcji), ponadto w celach prowadzenia korespondencji z Tobą.</p>
            <p>Twoje dane mogą ponadto zostać przekazane firmom zajmującym się obsługą płatności.</p>
            <p>W sytuacji, gdy wyraziłeś zgodę na przetwarzanie Twoich danych w celach marketingowych i promocyjnych, zwłaszcza dotyczących usług podmiotów powiązanych z Administratorem, Twoje dane
                mogą
                zostać przekazane tym podmiotom.</p>
            <p>W przypadku naruszenia przez Ciebie Regulaminu Serwisu lub naruszenia prawa, Twoje dane mogą zostać udostępnione organom wymiaru sprawiedliwości.</p>
            <p>Twoje dane nie są przekazywane do państw trzecich.</p>

            <h3>Jak długo będą przetwarzane Twoje dane osobowe?</h3>
            <p>Twoje dane osobowe będą przetwarzane przez czas niezbędny do świadczenia usług przez Administratora, czyli przez czas korzystania przez Ciebie z Serwisu oraz dokonywania Subskrypcji,
                ponadto przez okres przedawnienia roszczeń Administratora, a także przez okres niezbędny do wypełnienia obowiązków prawnych względem organów, w szczególności organów podatkowych.</p>
            <p>W sytuacji, gdy wyraziłeś zgodę na przetwarzanie Twoich danych w celach marketingowych i promocyjnych, dane osobowe przetwarzane będą do czasu cofnięcia zgody na jeden lub więcej wyżej
                wymienionych celów, lub do momentu ustania danego celu, który wymaga przetwarzania danych.</p>

            <h3>W jaki sposób Administrator będzie się z Tobą kontaktować?</h3>
            <p>Najpowszechniejszym sposobem kontaktu z Tobą – jako Użytkownikiem Serwisu – ze strony Administratora będzie kontakt za pośrednictwem poczty elektronicznej. Na Twój aktualny adres
                mailowy
                możesz otrzymywać od Administratora informacje o wszelkich ważnych – dla umowy sprzedaży dostępu do Aplikacji po dokonaniu Subskrypcji – okolicznościach, ewentualne informacje o nowych
                ofertach ze strony Administratora, ponadto istotne informacje o funkcjonowaniu Konta, w przypadku jego założenia w ramach Serwisu.</p>
            <p>Będziesz miał możliwość dokonania zmiany danych podanych przy Rejestracji, tj. adresu e-mail, loginu (nazwy Użytkownika) oraz hasła.</p>

            <h3>W jaki sposób będą zabezpieczone Twoje dane osobowe?</h3>
            <p>Bazy danych Serwisu zabezpieczone są przed wglądem nieupoważnionych osób trzecich. Twoje dane osobowe podane przy Rejestracji są zabezpieczone.
                Podane przez Ciebie hasła są szyfrowane w sposób uniemożliwiający ich odczytanie.</p>

            <h3>Co to są pliki cookies i w jakich celach są wykorzystywane?</h3>
            <p>To niewielkie informacje, nazywane ciasteczkami (z ang. cookie – ciastko), wysyłane przez Serwis, który odwiedzasz i zapisywane na Twoim urządzeniu końcowym (komputerze, laptopie,
                smartfonie), z którego korzystasz jako Użytkownik podczas przeglądania Serwisu.</p>
            <p>W plikach „cookies”, składających się z szeregu liter i cyfr, znajdują się różne informacje niezbędne do prawidłowego funkcjonowania Serwisu, np. zwłaszcza w plikach wymagających
                autoryzacji – m.in. podczas logowania do Twojego Konta.</p>
            <p>Serwis może prawidłowo działać dzięki wykorzystaniu „cookies”. Ciasteczka umożliwiają m.in. zapamiętanie Twoich preferencji i personalizowanie (dostosowywanie do Twoich potrzeb)
                wyświetlanych treści oraz dopasowanie ewentualnych reklam.</p>
            <p>Dane osobowe gromadzone przy użyciu „cookies” mogą być zbierane wyłącznie w celu wykonywania określonych funkcji na Twoją rzecz, czyli np. zapamiętania Twojego logowania do Serwisu czy
                zapamiętania przeglądanych podstron w Serwisie. Takie dane są zaszyfrowane w sposób uniemożliwiający dostęp do nich osobom nieuprawnionym.</p>
            <p>Każda przeglądarka pozwala na zablokowanie przyjmowania ciasteczek, jednak Administrator nie może w takim wypadku zagwarantować poprawnego działania Serwisu po zablokowaniu przyjmowania
                ciasteczek.</p>

            <h3>Jakie uprawnienia Ci przysługują w związku z przetwarzaniem przez nas Twoich danych osobowych?</h3>
            <p>Użytkowniku, przysługują Ci następujące prawa w zakresie Twoich danych osobowych:</p>
            <ol className="legal-list">
                <li>prawo do dostępu do swoich danych oraz otrzymania ich kopii (koszt sporządzenia kopii obciąża jednak Ciebie),</li>
                <li>prawo do sprostowania (poprawiania) swoich danych,</li>
                <li>prawo do żądania usunięcia, ograniczenia lub wniesienia sprzeciwu wobec ich przetwarzania (prawo do wniesienia sprzeciwu z przyczyn związanych z Twoją szczególną sytuacją dotyczy
                    przetwarzania danych, których przetwarzanie jest niezbędne do celów wynikających z prawnie uzasadnionych interesów realizowanych przez Administratora lub przez stronę trzecią /
                    natomiast w sytuacji przetwarzania danych osobowych na potrzeby marketingu bezpośredniego, możesz wnieść sprzeciw w dowolnym momencie, w zakresie, w jakim przetwarzanie jest
                    związane z
                    takim marketingiem bezpośrednim),
                </li>
                <li>prawo do przenoszenia danych,</li>
                <li>prawo do wniesienia skargi do organu nadzorczego w rozumieniu art. 77 RODO (Organem nadzorczym w Polsce jest Prezes Urzędu Ochrony Danych Osobowych, adres do korespondencji: ul.
                    Stawki
                    2, 00-193 Warszawa), ewentualnie organu nadzorczego w kraju, w którym korzystasz z Serwisu.
                </li>
                <li>poinformowania Inspektora Ochrony Danych o wszelkich ewentualnych zdarzeniach dot. naruszenia bezpieczeństwa przetwarzania Twoich danych osobowych – dane kontaktowe zostały podane
                    wyżej.
                </li>
            </ol>
            <p>Ponadto przysługuje Ci prawo do wycofania zgody/wszystkich zgód na przetwarzanie Twoich danych osobowych (zwłaszcza w celach marketingowych i promocyjnych dotyczących usług oferowanych
                przez Administratora), w przypadku, gdy podstawą przetwarzania jest jedna lub kilka zgód udzielonych odrębnie), ale cofnięcie zgody/zgód nie wpływa na zgodność z prawem przetwarzania,
                którego dokonano przed jej wycofaniem zgodnie z prawem. Cofnięcie zgody/zgód może zostać dokonane w ten sam sposób, co udzielenie zgody/zgód.</p>
            <p>Cofnięcie zgody możliwe jest ponadto:</p>
            <ol className="legal-list">
                <li>w formie pisemnej, pisząc na adres: ul. Wita Stwosza 16, 50-148 Wrocław;</li>
                <li>w formie telefonicznej, kontaktując się z numerem telefonu: +48535167580;</li>
                <li>w formie elektronicznej, pisząc na adres poczty elektronicznej e-mail: info@archiplaner.pl.</li>
            </ol>

            <p>W odniesieniu do Twoich danych nie następuje zautomatyzowane podejmowanie decyzji, Administrator nie profiluje danych.</p>
        </Container>
    </>
)