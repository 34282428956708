


// Template Map
export default {
  '__react_static_root__/src/pages/404': require('__react_static_root__/src/pages/404').default,
'__react_static_root__/src/pages/index.js': require('__react_static_root__/src/pages/index.js').default,
'__react_static_root__/src/pages/polityka-prywatnosci.js': require('__react_static_root__/src/pages/polityka-prywatnosci.js').default,
'__react_static_root__/src/pages/regulamin.js': require('__react_static_root__/src/pages/regulamin.js').default,
'__react_static_root__/src/pages/rzuty-mieszkan.js': require('__react_static_root__/src/pages/rzuty-mieszkan.js').default,
'__react_static_root__/src/pages/poradnik.js': require('__react_static_root__/src/pages/poradnik.js').default,
'__react_static_root__/src/help.js': require('__react_static_root__/src/help.js').default,
'__react_static_root__/src/pages/aktualnosci.js': require('__react_static_root__/src/pages/aktualnosci.js').default,
'__react_static_root__/src/news.js': require('__react_static_root__/src/news.js').default
}

export const notFoundTemplate = '__react_static_root__/src/pages/404'

