//@flow
import React from 'react';
import Lightbox, {ILightBoxProps} from "react-image-lightbox";
import 'react-image-lightbox/style.css';
import {useMsgs} from "./Language";

/**
 * Nakładka na Lightbox, która ustawia klucze językowe.
 */
export const LangLightBox = ({...props}: ILightBoxProps) => {
    const msgs=useMsgs();
    return <Lightbox
        nextLabel={msgs.commons.lbNextLabel}
        prevLabel={msgs.commons.lbPrevLabel}
        closeLabel={msgs.commons.lbCloseLabel}
        zoomInLabel={msgs.commons.lbZoomInLabel}
        zoomOutLabel={msgs.commons.lbZoomOutLabel}
        {...props}
    />

}

/**
 * Informacja o pojedynczym obrazku
 */
export type ImageInfo = {
    url: string;
    thumbnail: string;
}

