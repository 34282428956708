//@flow
import React from 'react';

export type SvgIconProps = {
    className?: string;
}

export class Icon extends React.Component {

    /** Zębatka */
    static Settings=(props: SvgIconProps) => (<svg className={props.className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g className="nc-icon-wrapper" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="#464d57" stroke="#464d57">
            <circle data-color="color-2" cx="12" cy="12" r="3" fill="none" strokeMiterlimit="10"/>
            <path
                d="M20,12a8.049,8.049,0,0,0-.188-1.713l2.714-2.055-2-3.464L17.383,6.094a7.987,7.987,0,0,0-2.961-1.719L14,1H10L9.578,4.375A7.987,7.987,0,0,0,6.617,6.094L3.474,4.768l-2,3.464,2.714,2.055a7.9,7.9,0,0,0,0,3.426L1.474,15.768l2,3.464,3.143-1.326a7.987,7.987,0,0,0,2.961,1.719L10,23h4l.422-3.375a7.987,7.987,0,0,0,2.961-1.719l3.143,1.326,2-3.464-2.714-2.055A8.049,8.049,0,0,0,20,12Z"
                fill="none" stroke="#1f788a" strokeMiterlimit="10"/>
        </g>
    </svg>);

    /** Rzut */
    static Sketch=(props: SvgIconProps) => (<svg className={props.className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g className="nc-icon-wrapper" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="#464d57" stroke="#464d57">
            <path d="M23,18V4a3,3,0,0,0-3-3H19V15h1a3,3,0,0,1,0,6H1V4H16" fill="none" stroke="#1f788a" strokeMiterlimit="10" data-cap="butt"/>
            <polyline points="9 15 9 17 15 17 15 8 5 8 5 17" fill="none" strokeMiterlimit="10" data-color="color-2"/>
            <line x1="11" y1="11" x2="11" y2="8" fill="none" strokeMiterlimit="10" data-color="color-2"/>
        </g>
    </svg>);

    /** Wyposażenie/meble */
    static Furniture=(props: SvgIconProps) => (<svg className={props.className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g className="nc-icon-wrapper" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="#464d57" stroke="#464d57">
            <polyline points="21 14 21 10 11 10 11 14" fill="none" stroke="#1f788a" strokeMiterlimit="10"/>
            <polygon points="19 14 19 17 13 17 13 14 9 14 9 21 23 21 23 14 19 14" fill="none" stroke="#1f788a" strokeMiterlimit="10"/>
            <line x1="11" y1="21" x2="11" y2="23" fill="none" stroke="#1f788a" strokeMiterlimit="10"/>
            <line x1="21" y1="21" x2="21" y2="23" fill="none" stroke="#1f788a" strokeMiterlimit="10"/>
            <line x1="5" y1="23" x2="5" y2="10" fill="none" strokeMiterlimit="10" data-color="color-2"/>
            <polygon points="7 1 3 1 1 5 1 7 9 7 9 5 7 1" fill="none" strokeMiterlimit="10" data-color="color-2"/>
        </g>
    </svg>);

    static Export=(props: SvgIconProps) => (<svg className={props.className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g className="nc-icon-wrapper" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="#464d57" stroke="#464d57">
            <polyline points="20 16 20 22 1 22 1 6 4 6" fill="none" stroke="#1f788a" strokeMiterlimit="10"/>
            <path data-color="color-2" d="M22,9,14,2V6C8.8,6,5,7.9,5,16c1.9-2.5,3.7-4,9-4v4Z" fill="none" strokeMiterlimit="10"/>
        </g>
    </svg>);

    /** Prostokąt/karta z plusem w prawym górnym rogu */
    static New=(props: SvgIconProps) => (<svg className={props.className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g className="nc-icon-wrapper" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="#464d57" stroke="#464d57">
            <polyline points="19 14 19 22 2 22 2 5 10 5" fill="none" stroke="#1f788a" strokeMiterlimit="10"/>
            <line data-color="color-2" x1="19" y1="1" x2="19" y2="9" fill="none" strokeMiterlimit="10"/>
            <line data-color="color-2" x1="15" y1="5" x2="23" y2="5" fill="none" strokeMiterlimit="10"/>
        </g>
    </svg>);

    /** Kartka z gwiazdką w środku */
    static Favourite=(props: SvgIconProps) => (<svg className={props.className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" stroke="#464d57" fill="none">
        <g stroke="#1f788a" strokeMiterlimit="10" strokeLinejoin="round" strokeWidth="2">
            <path d="M44 2v12h12" strokeLinecap="round"/>
            <path d="M44 2H8v60h48V14z"/>
        </g>
        <path strokeMiterlimit="10" d="M32 19.636l4.017 8.14L45 29.08l-6.5 6.336 1.534 8.947L32 40.14l-8.034 4.224 1.534-8.947L19 29.08l8.983-1.305z" strokeLinejoin="round"
              strokeWidth="2"/>
    </svg>);

    static SketchBig=(props: SvgIconProps) => (<svg className={props.className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" stroke="#464d57" fill="none" strokeMiterlimit="10" strokeLinecap="round"
                                                    strokeLinejoin="round" strokeWidth="2">
        <path d="M20 44v6h24V20H10v30"/>
        <path d="M61,49V13a8,8,0,0,0-8-8H51V41h1.7a8.194,8.194,0,0,1,8.135,6.346A8.009,8.009,0,0,1,53,57H3V13H46" stroke="#1f788a"/>
        <path d="M28 20v14h-8"/>
    </svg>);

    static SafeHome=(props: SvgIconProps) => (<svg className={props.className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" stroke="#464d57" fill="none">
        <path d="M2 28L32 4l30 24m-52 2v30h44V30" stroke="#1f788a" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
        <path d="M42.083 29.984A6.412 6.412 0 0 0 32 31.03a6.413 6.413 0 0 0-10.083-1.046 6.943 6.943 0 0 0 0 9.58L32 50l10.083-10.436a6.943 6.943 0 0 0 0-9.58z"
              strokeMiterlimit="10" strokeLinejoin="round" strokeWidth="2"/>
    </svg>);

    static LocationOk=(props: SvgIconProps) => (<svg className={props.className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" stroke="#464d57" fill="none">
        <g stroke="#1f788a" strokeLinejoin="round" strokeWidth="2">
            <path d="M34 55.56L29 61S7 39 7 24.1C7 10.113 18.37 2 29 2s22 8.113 22 22.1c0 5.608-3.114 12.22-7 18.288" strokeMiterlimit="10" strokeLinecap="round"/>
            <circle cx="29" cy="24" r="8"/>
        </g>
        <path strokeMiterlimit="10" d="M38 48l6 6 14-14" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
    </svg>);

    static DocumentHome=(props: SvgIconProps) => (<svg className={props.className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" stroke="#464d57" fill="none">
        <path d="M20 17h24M20 27h24M20 37h24M20 47h10m5 15H8V2h48v31" stroke="#1f788a" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"
              strokeWidth="2"/>
        <path strokeMiterlimit="10" d="M52 41l-10 8v13h7v-7h6v7h7V49l-10-8z" strokeLinejoin="round" strokeWidth="2"/>
    </svg>);

    static Back=(props) => (<svg className={props.className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="#1f788a">
        <path d="M5 7h10a1 1 0 0 1 1 1h0a1 1 0 0 1-1 1H5v3L0 8l5-4v3zm3-4h7a1 1 0 0 0 1-1h0a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1h0a1 1 0 0 0 1 1zm0 12h7a1 1 0 0 0 1-1h0a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1h0a1 1 0 0 0 1 1z"/>
    </svg>);

    static Email=(props) => (<svg className={props.className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <g className="nc-icon-wrapper" strokeWidth="1" fill="#1f788a" stroke="#1f788a">
            <path fill="none" stroke="#1f788a" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10"
                  d="M14.5,13.5h-13 c-0.552,0-1-0.448-1-1v-10c0-0.552,0.448-1,1-1h13c0.552,0,1,0.448,1,1v10C15.5,13.052,15.052,13.5,14.5,13.5z"/>
            <polyline data-color="color-2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points=" 2.5,4.5 8,9 13.5,4.5 "/>
            <line data-color="color-2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="2.5" y1="10.5" x2="4" y2="9"/>
            <line data-color="color-2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="13.5" y1="10.5" x2="12" y2="9"/>
        </g>
    </svg>);

    static Password=(props: SvgIconProps) => (<svg className={props.className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g className="nc-icon-wrapper" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="#1f788a" stroke="#1f788a">
            <rect x="3" y="11" width="18" height="12" fill="none" stroke="#1f788a" strokeMiterlimit="10"/>
            <circle data-color="color-2" cx="12" cy="17" r="2" fill="none" strokeMiterlimit="10"/>
            <path data-cap="butt" d="M17,11V6a4.951,4.951,0,0,0-4.9-5H12A4.951,4.951,0,0,0,7,5.9V11" fill="none" stroke="#1f788a" strokeMiterlimit="10"/>
        </g>
    </svg>);

    static Admin=(props: SvgIconProps) => (<svg className={props.className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g className="nc-icon-wrapper" strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2" fill="#1f788a" stroke="#1f788a">
            <polygon points="12 6 13.545 9.131 17 9.633 14.5 12.07 15.09 15.511 12 13.886 8.91 15.511 9.5 12.07 7 9.633 10.455 9.131 12 6" data-cap="butt" data-color="color-2" data-stroke="none"
                     stroke="none" strokeLinecap="butt"/>
            <path d="M12,22S2,18,2,5L12,2,22,5C22,18,12,22,12,22Z" fill="none" stroke="#1f788a" strokeMiterlimit="10"/>
        </g>
    </svg>);

    static Payment=(props) => (<svg className={props.className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g className="nc-icon-wrapper" strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2" fill="#1f788a" stroke="#1f788a">
            <path
                d="M15.983,14.214a2.554,2.554,0,0,1-.887,2,4.509,4.509,0,0,1-2.561.971V19H11.423V17.234A8.628,8.628,0,0,1,8.017,16.6V14.833a8.577,8.577,0,0,0,1.682.59,7.617,7.617,0,0,0,1.724.247V12.54l-.7-.26a4.954,4.954,0,0,1-2.005-1.259A2.675,2.675,0,0,1,8.084,9.21a2.357,2.357,0,0,1,.9-1.9,4.455,4.455,0,0,1,2.44-.9V5h1.112V6.381a9.225,9.225,0,0,1,3.222.686L15.146,8.6a9,9,0,0,0-2.611-.619v3.046l.636.243a5.8,5.8,0,0,1,2.2,1.289A2.385,2.385,0,0,1,15.983,14.214ZM10.05,9.193a1.119,1.119,0,0,0,.3.794,3.023,3.023,0,0,0,1.076.628v-2.6a1.956,1.956,0,0,0-1.022.41A.985.985,0,0,0,10.05,9.193Zm3.958,5.146a1.041,1.041,0,0,0-.339-.8,3.466,3.466,0,0,0-1.134-.607V15.6Q14.008,15.377,14.008,14.339Z"
                data-cap="butt" data-color="color-2" data-stroke="none" stroke="none" strokeLinecap="butt"/>
            <polygon points="21.35 21.35 17.5 21.9 18.05 18.05 21.35 21.35" fill="#1f788a" data-cap="butt" data-stroke="none" stroke="none" strokeLinecap="butt"/>
            <polygon points="5.95 5.95 6.5 2.1 2.65 2.65 5.95 5.95" fill="#1f788a" data-cap="butt" data-stroke="none" stroke="none" strokeLinecap="butt"/>
            <polygon points="21.35 21.35 17.5 21.9 18.05 18.05 21.35 21.35" fill="none" stroke="#1f788a" strokeMiterlimit="10" data-cap="butt" strokeLinecap="butt"/>
            <polygon points="5.95 5.95 6.5 2.1 2.65 2.65 5.95 5.95" fill="none" stroke="#1f788a" strokeMiterlimit="10" data-cap="butt" strokeLinecap="butt"/>
            <path d="M19.778,19.778A11,11,0,0,0,12,1" fill="none" stroke="#1f788a" strokeMiterlimit="10" data-cap="butt" strokeLinecap="butt"/>
            <path d="M4.222,4.222A11,11,0,0,0,12,23" fill="none" stroke="#1f788a" strokeMiterlimit="10" data-cap="butt" strokeLinecap="butt"/>
        </g>
    </svg>);

    static UserSettings=(props: SvgIconProps) => (<svg className={props.className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g className="nc-icon-wrapper" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="#1f788a" stroke="#1f788a">
            <path data-cap="butt" fill="none" stroke="#1f788a" strokeMiterlimit="10" d="M10,13 c-2.824,0-5.329,0.638-6.975,1.193C1.81,14.604,1,15.749,1,17.032V21h8"/>
            <path fill="none" stroke="#1f788a" strokeMiterlimit="10" d="M10,13L10,13 c-2.761,0-5-3.239-5-6V6c0-2.761,2.239-5,5-5h0c2.761,0,5,2.239,5,5v1C15,9.761,12.761,13,10,13z"/>
            <polygon data-color="color-2" fill="none" strokeMiterlimit="10" points=" 16,23 13,23 13,20 19,14 22,17 "/>
        </g>
    </svg>);

    static Schema = (props: SvgIconProps) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g className={props.className} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="#1f788a" stroke="#1f788a">
            <path d="M23,18V4a3,3,0,0,0-3-3H19V15h1a3,3,0,0,1,0,6H1V4H16" fill="none" stroke="#1f788a" strokeMiterlimit="10" data-cap="butt"/>
            <polyline points="9 15 9 17 15 17 15 8 5 8 5 17" fill="none" strokeMiterlimit="10" data-color="color-2"/>
            <line x1="11" y1="11" x2="11" y2="8" fill="none" strokeMiterlimit="10" data-color="color-2"/>
        </g>
    </svg>;

    static Admin2 = (props: SvgIconProps) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g className={props.className} strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2" fill="#1f788a" stroke="#1f788a">
            <polygon points="12 6 13.545 9.131 17 9.633 14.5 12.07 15.09 15.511 12 13.886 8.91 15.511 9.5 12.07 7 9.633 10.455 9.131 12 6" data-cap="butt" data-color="color-2" data-stroke="none"
                     stroke="none" strokeLinecap="butt"/>
            <path d="M12,22S2,18,2,5L12,2,22,5C22,18,12,22,12,22Z" fill="none" stroke="#1f788a" strokeMiterlimit="10"/>
        </g>
    </svg>;

    static Payment2 = (props: SvgIconProps) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g className={props.className} strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2" fill="#1f788a" stroke="#1f788a">
            <path
                d="M15.983,14.214a2.554,2.554,0,0,1-.887,2,4.509,4.509,0,0,1-2.561.971V19H11.423V17.234A8.628,8.628,0,0,1,8.017,16.6V14.833a8.577,8.577,0,0,0,1.682.59,7.617,7.617,0,0,0,1.724.247V12.54l-.7-.26a4.954,4.954,0,0,1-2.005-1.259A2.675,2.675,0,0,1,8.084,9.21a2.357,2.357,0,0,1,.9-1.9,4.455,4.455,0,0,1,2.44-.9V5h1.112V6.381a9.225,9.225,0,0,1,3.222.686L15.146,8.6a9,9,0,0,0-2.611-.619v3.046l.636.243a5.8,5.8,0,0,1,2.2,1.289A2.385,2.385,0,0,1,15.983,14.214ZM10.05,9.193a1.119,1.119,0,0,0,.3.794,3.023,3.023,0,0,0,1.076.628v-2.6a1.956,1.956,0,0,0-1.022.41A.985.985,0,0,0,10.05,9.193Zm3.958,5.146a1.041,1.041,0,0,0-.339-.8,3.466,3.466,0,0,0-1.134-.607V15.6Q14.008,15.377,14.008,14.339Z"
                data-cap="butt" data-color="color-2" data-stroke="none" stroke="none" strokeLinecap="butt"/>
            <polygon points="21.35 21.35 17.5 21.9 18.05 18.05 21.35 21.35" fill="#1f788a" data-cap="butt" data-stroke="none" stroke="none" strokeLinecap="butt"/>
            <polygon points="5.95 5.95 6.5 2.1 2.65 2.65 5.95 5.95" fill="#1f788a" data-cap="butt" data-stroke="none" stroke="none" strokeLinecap="butt"/>
            <polygon points="21.35 21.35 17.5 21.9 18.05 18.05 21.35 21.35" fill="none" stroke="#1f788a" strokeMiterlimit="10" data-cap="butt" strokeLinecap="butt"/>
            <polygon points="5.95 5.95 6.5 2.1 2.65 2.65 5.95 5.95" fill="none" stroke="#1f788a" strokeMiterlimit="10" data-cap="butt" strokeLinecap="butt"/>
            <path d="M19.778,19.778A11,11,0,0,0,12,1" fill="none" stroke="#1f788a" strokeMiterlimit="10" data-cap="butt" strokeLinecap="butt"/>
            <path d="M4.222,4.222A11,11,0,0,0,12,23" fill="none" stroke="#1f788a" strokeMiterlimit="10" data-cap="butt" strokeLinecap="butt"/>
        </g>
    </svg>;

    static PaymentHistory = (props: SvgIconProps) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g className={props.className} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="#1f788a" stroke="#1f788a">
            <path d="M2,12A10,10,0,1,0,12,2,9.836,9.836,0,0,0,3.1,7.5" fill="none" stroke="#1f788a" strokeMiterlimit="10" data-cap="butt"/>
            <polyline points="2.264 2.57 3.1 7.5 8.03 6.664" fill="none" stroke="#1f788a" strokeMiterlimit="10"/>
            <path
                d="M15.6,13.868a2.273,2.273,0,0,1-.825,1.833,4.1,4.1,0,0,1-2.31.829V18h-.982V16.558a7.96,7.96,0,0,1-3.07-.617V14.048a8.311,8.311,0,0,0,1.5.545,8.061,8.061,0,0,0,1.568.28V12.649L11,12.463a5.353,5.353,0,0,1-2.012-1.216A2.334,2.334,0,0,1,8.4,9.622a2.121,2.121,0,0,1,.814-1.71A4.144,4.144,0,0,1,11.484,7.1V6h.982V7.069a8.134,8.134,0,0,1,2.97.66l-.674,1.678a7.754,7.754,0,0,0-2.3-.559v2.116a11,11,0,0,1,1.991.932,2.72,2.72,0,0,1,.867.868A2.142,2.142,0,0,1,15.6,13.868ZM10.558,9.622a.679.679,0,0,0,.219.52,2.586,2.586,0,0,0,.707.42V8.876Q10.559,9.013,10.558,9.622Zm2.884,4.354a.647.647,0,0,0-.244-.509,3.171,3.171,0,0,0-.732-.431v1.786Q13.441,14.657,13.442,13.976Z"
                data-cap="butt" data-color="color-2" data-stroke="none" stroke="none"/>
        </g>
    </svg>;

    static User = (props: SvgIconProps) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g className={props.className} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="#1f788a" stroke="#1f788a">
            <path data-cap="butt" fill="none" stroke="#1f788a" strokeMiterlimit="10" d="M10,13 c-2.824,0-5.329,0.638-6.975,1.193C1.81,14.604,1,15.749,1,17.032V21h8"/>
            <path fill="none" stroke="#1f788a" strokeMiterlimit="10" d="M10,13L10,13 c-2.761,0-5-3.239-5-6V6c0-2.761,2.239-5,5-5h0c2.761,0,5,2.239,5,5v1C15,9.761,12.761,13,10,13z"/>
            <polygon data-color="color-2" fill="none" strokeMiterlimit="10" points=" 16,23 13,23 13,20 19,14 22,17 "/>
        </g>
    </svg>;

    static Home = (props: SvgIconProps) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g className={props.className} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="#1f788a" stroke="#1f788a">
            <polyline points="1 11 12 2 23 11" fill="none" stroke="#1f788a" strokeMiterlimit="10" data-cap="butt"/>
            <polyline points="4 13 4 23 20 23 20 13" fill="none" stroke="#1f788a" strokeMiterlimit="10"/>
            <path d="M15.361,11.631A2.2,2.2,0,0,0,12,11.964a2.2,2.2,0,0,0-3.361-.333,2.137,2.137,0,0,0,0,3.048L12,18l3.361-3.321A2.137,2.137,0,0,0,15.361,11.631Z" fill="none"
                  strokeMiterlimit="10" data-color="color-2"/>
        </g>
    </svg>;

    static Invoice = (props: SvgIconProps) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g className={props.className} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="#1f788a" stroke="#1f788a">
            <polygon fill="none" stroke="#1f788a" strokeMiterlimit="10" points="3,1 21,1 21,22 18,20 15,22 12,20 9,22 6,20 3,22 "/>
            <line data-color="color-2" fill="none" strokeMiterlimit="10" x1="7" y1="7" x2="12" y2="7"/>
            <line data-color="color-2" fill="none" strokeMiterlimit="10" x1="16" y1="7" x2="17" y2="7"/>
            <line data-color="color-2" fill="none" strokeMiterlimit="10" x1="7" y1="11" x2="12" y2="11"/>
            <line data-color="color-2" fill="none" strokeMiterlimit="10" x1="16" y1="11" x2="17" y2="11"/>
            <line data-color="color-2" fill="none" strokeMiterlimit="10" x1="7" y1="15" x2="12" y2="15"/>
            <line data-color="color-2" fill="none" strokeMiterlimit="10" x1="16" y1="15" x2="17" y2="15"/>
        </g>
    </svg>;

    static Locked = (props: SvgIconProps) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g className={props.className} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="#1f788a" stroke="#1f788a">
            <rect x="3" y="11" width="18" height="12" fill="none" stroke="#1f788a" strokeMiterlimit="10"/>
            <circle data-color="color-2" cx="12" cy="17" r="2" fill="none" strokeMiterlimit="10"/>
            <path data-cap="butt" d="M17,11V6a4.951,4.951,0,0,0-4.9-5H12A4.951,4.951,0,0,0,7,5.9V11" fill="none" stroke="#1f788a" strokeMiterlimit="10"/>
        </g>
    </svg>;

    static LockOpen = (props: SvgIconProps) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g className={props.className} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="#1f788a" stroke="#1f788a">
            <rect x="3" y="11" width="18" height="12" fill="none" stroke="#1f788a" strokeMiterlimit="10"/>
            <circle data-color="color-2" cx="12" cy="17" r="2" fill="none" strokeMiterlimit="10"/>
            <path data-cap="butt" d="M17,6a4.951,4.951,0,0,0-4.9-5H12A4.951,4.951,0,0,0,7,5.9V11" fill="none" stroke="#1f788a" strokeMiterlimit="10"/>
        </g>
    </svg>;

    static Favourite2 = (props: SvgIconProps) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g className={props.className} strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2" fill="#1f788a" stroke="#1f788a">
            <polygon points="12 2.489 15.09 8.751 22 9.755 17 14.629 18.18 21.511 12 18.261 5.82 21.511 7 14.629 2 9.755 8.91 8.751 12 2.489" fill="none" stroke="#1f788a" strokeMiterlimit="10"/>
        </g>
    </svg>;

    /** Kółko ze znakiem zapytania w środku */
    static Help = (props: SvgIconProps) => <svg className={props.className || "svg-font"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="#1f788a" stroke="#1f788a">
            <circle cx="12" cy="12" r="11" fill="none" stroke="#1f788a" strokeMiterlimit="10"/>
            <circle data-color="color-2" data-stroke="none" cx="12" cy="18" r="1" stroke="none"/>
            <path data-color="color-2" d="M9.853,6.56c1.98-.875,4.542-.771,5.417.645s.271,3.063-1.229,4.334S12,13.5,12,14.5" fill="none" strokeMiterlimit="10"/>
        </g>
    </svg>

    static Help2 = (props: SvgIconProps) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g className={props.className} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="#1f788a" stroke="#1f788a">
            <path fill="none" stroke="#1f788a" strokeMiterlimit="10" d="M17,4.3c3,1.7,5,5,5,8.7 c0,5.5-4.5,10-10,10S2,18.5,2,13c0-3.7,2-6.9,5-8.7"/>
            <line data-color="color-2" fill="none" strokeMiterlimit="10" x1="12" y1="1" x2="12" y2="8"/>
        </g>
    </svg>;

    static UserInfo = (props: SvgIconProps) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g className={props.className} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="#1f788a" stroke="#1f788a">
            <path data-cap="butt" data-color="color-2" fill="none" strokeMiterlimit="10" d="M18.989,20.495 c-0.135-2.388-1.497-3.146-3.489-3.81c-1.522-0.507-1.941-2.105-2.056-2.953"/>
            <path data-cap="butt" data-color="color-2" fill="none" strokeMiterlimit="10" d="M10.555,13.731 c-0.113,0.844-0.524,2.444-2.055,2.954c-1.992,0.664-3.356,1.42-3.491,3.808"/>
            <path data-color="color-2" fill="none" strokeMiterlimit="10" d="M12,14L12,14 c-2.209,0-4-1.791-4-4V9c0-2.209,1.791-4,4-4h0c2.209,0,4,1.791,4,4v1C16,12.209,14.209,14,12,14z"/>
            <circle fill="none" stroke="#1f788a" strokeMiterlimit="10" cx="12" cy="12" r="11"/>
        </g>
    </svg>;

    static PacketInfo = (props: SvgIconProps) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g className={props.className} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="#1f788a" stroke="#1f788a">
            <polygon points="12 6.767 13.545 9.898 17 10.4 14.5 12.837 15.09 16.278 12 14.653 8.91 16.278 9.5 12.837 7 10.4 10.455 9.898 12 6.767" fill="none" strokeMiterlimit="10"
                     data-color="color-2"/>
            <circle cx="12" cy="12" r="11" fill="none" stroke="#1f788a" strokeMiterlimit="10" data-cap="butt"/>
            <circle cx="12" cy="19" r="1" fill="#1f788a" data-cap="butt" data-stroke="none" stroke="none"/>
            <circle cx="18.657" cy="14.163" r="1" fill="#1f788a" data-cap="butt" data-stroke="none" stroke="none"/>
            <circle cx="16.114" cy="6.337" r="1" fill="#1f788a" data-cap="butt" data-stroke="none" stroke="none"/>
            <circle cx="7.886" cy="6.337" r="1" fill="#1f788a" data-cap="butt" data-stroke="none" stroke="none"/>
            <circle cx="5.343" cy="14.163" r="1" fill="#1f788a" data-cap="butt" data-stroke="none" stroke="none"/>
        </g>
    </svg>;

    static City = (props: SvgIconProps) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g className={props.className} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="#1f788a" stroke="#1f788a">
            <polyline points="5 23 1 23 1 13 5 13" fill="none" stroke="#1f788a" strokeMiterlimit="10"/>
            <line x1="9" y1="16" x2="11" y2="16" fill="none" strokeMiterlimit="10" data-color="color-2"/>
            <line x1="9" y1="19" x2="11" y2="19" fill="none" strokeMiterlimit="10" data-color="color-2"/>
            <line x1="9" y1="13" x2="11" y2="13" fill="none" strokeMiterlimit="10" data-color="color-2"/>
            <polyline points="13 6 13 2 23 5 23 23 5 23 5 9 15 9 15 23" fill="none" stroke="#1f788a" strokeMiterlimit="10"/>
            <line x1="19" y1="19" x2="19" y2="9" fill="none" strokeMiterlimit="10" data-color="color-2"/>
        </g>
    </svg>;

    static Phone = (props: SvgIconProps) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g className={props.className} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="#1f788a" stroke="#1f788a">
            <path fill="none" stroke="#1f788a" strokeMiterlimit="10" d="M17,15l-3,3l-8-8l3-3L4,2 L1,5c0,9.941,8.059,18,18,18l3-3L17,15z"/>
        </g>
    </svg>;

    static Envelope = (props: SvgIconProps) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <g className={props.className} strokeWidth="1" fill="#1f788a" stroke="#1f788a">
            <path fill="none" stroke="#1f788a" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10"
                  d="M14.5,13.5h-13 c-0.552,0-1-0.448-1-1v-10c0-0.552,0.448-1,1-1h13c0.552,0,1,0.448,1,1v10C15.5,13.052,15.052,13.5,14.5,13.5z"/>
            <polyline data-color="color-2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points=" 2.5,4.5 8,9 13.5,4.5 "/>
            <line data-color="color-2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="2.5" y1="10.5" x2="4" y2="9"/>
            <line data-color="color-2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="13.5" y1="10.5" x2="12" y2="9"/>
        </g>
    </svg>;

    static Street = (props: SvgIconProps) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g className={props.className} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="#1f788a" stroke="#1f788a">
            <polyline points="12 7 5 7 3 9 5 11 12 11" fill="none" stroke="#1f788a" strokeMiterlimit="10"/>
            <polyline points="12 3 19 3 21 5 19 7 12 7" fill="none" strokeMiterlimit="10" data-cap="butt" data-color="color-2"/>
            <polyline points="12 11 19 11 21 13 19 15 12 15" fill="none" strokeMiterlimit="10" data-cap="butt" data-color="color-2"/>
            <line x1="15" y1="23" x2="9" y2="23" fill="none" stroke="#1f788a" strokeMiterlimit="10"/>
            <line x1="12" y1="23" x2="12" y2="1" fill="none" stroke="#1f788a" strokeMiterlimit="10"/>
        </g>
    </svg>;

    static Users = (props: SvgIconProps) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g className={props.className} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="#1f788a" stroke="#1f788a">
            <path data-color="color-2" fill="none" strokeMiterlimit="10" d="M4,7L4,7 C2.895,7,2,6.105,2,5v0c0-1.105,0.895-2,2-2h0c1.105,0,2,0.895,2,2v0C6,6.105,5.105,7,4,7z"/>
            <path data-color="color-2" fill="none" strokeMiterlimit="10" d="M6,21H3v-4 l-2,0v-5c0-1.105,0.895-2,2-2h1"/>
            <path data-color="color-2" fill="none" strokeMiterlimit="10" d="M20,7L20,7 c1.105,0,2-0.895,2-2v0c0-1.105-0.895-2-2-2h0c-1.105,0-2,0.895-2,2v0C18,6.105,18.895,7,20,7z"/>
            <path data-color="color-2" fill="none" strokeMiterlimit="10" d="M18,21h3v-4 l2,0v-5c0-1.105-0.895-2-2-2h-1"/>
            <path fill="none" stroke="#1f788a" strokeMiterlimit="10" d="M12,7L12,7 c-1.657,0-3-1.343-3-3v0c0-1.657,1.343-3,3-3h0c1.657,0,3,1.343,3,3v0C15,5.657,13.657,7,12,7z"/>
            <path fill="none" stroke="#1f788a" strokeMiterlimit="10" d="M15,23H9v-6H7v-5 c0-1.105,0.895-2,2-2h6c1.105,0,2,0.895,2,2v6h-2V23z"/>
        </g>
    </svg>;

    static Home = (props: SvgIconProps) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g className={props.className} fill="#1f788a">
            <path d="M23.633,10.226l-11-9a1,1,0,0,0-1.266,0l-11,9a1,1,0,0,0,1.266,1.548L3,10.656V22a1,1,0,0,0,1,1h6V17h4v6h6a1,1,0,0,0,1-1V10.656l1.367,1.118a1,1,0,1,0,1.266-1.548Z"
                  fill="#1f788a"/>
        </g>
    </svg>;

    static Cut = (props: SvgIconProps) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g className={props.className} strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2" fill="#1f788a" stroke="#1f788a">
            <line data-color="color-2" fill="none" strokeMiterlimit="10" x1="17" y1="8" x2="15" y2="8"/>
            <line data-color="color-2" fill="none" strokeMiterlimit="10" x1="17" y1="22" x2="15" y2="22"/>
            <line data-color="color-2" fill="none" strokeMiterlimit="10" x1="11" y1="22" x2="9" y2="22"/>
            <line data-color="color-2" fill="none" strokeMiterlimit="10" x1="23" y1="16" x2="23" y2="14"/>
            <polyline data-color="color-2" fill="none" strokeMiterlimit="10" points=" 23,10 23,8 21,8 "/>
            <polyline data-color="color-2" fill="none" strokeMiterlimit="10" points=" 23,20 23,22 21,22 "/>
            <polyline data-color="color-2" fill="none" strokeMiterlimit="10" points=" 3,20 3,22 5,22 "/>
            <circle fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#1f788a" strokeMiterlimit="10" cx="3" cy="13" r="2"/>
            <circle fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#1f788a" strokeMiterlimit="10" cx="3" cy="3" r="2"/>
            <line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#1f788a" strokeMiterlimit="10" x1="4.52" y1="4.286" x2="16" y2="14"/>
            <line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#1f788a" strokeMiterlimit="10" x1="4.52" y1="11.714" x2="16" y2="2"/>
        </g>
    </svg>;

    static PostalCode = (props: SvgIconProps) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g className={props.className} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="#1f788a" stroke="#1f788a">
            <polygon points="2 9 6 4 16 4 16 14 6 14 2 9" fill="none" stroke="#1f788a" strokeMiterlimit="10"/>
            <polyline points="8 18 8 20 18 20 22 15 20 13" fill="none" strokeMiterlimit="10" data-color="color-2"/>
        </g>
    </svg>;

    static NIP = (props: SvgIconProps) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g className={props.className} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="#1f788a" stroke="#1f788a">
            <polygon points="3 1 21 1 21 22 18 20 15 22 12 20 9 22 6 20 3 22 3 1" fill="none" stroke="#1f788a" strokeMiterlimit="10"/>
            <line x1="7" y1="15" x2="12" y2="15" fill="none" strokeMiterlimit="10" data-color="color-2"/>
            <line x1="16" y1="15" x2="17" y2="15" fill="none" strokeMiterlimit="10" data-color="color-2"/>
            <circle cx="12" cy="8" r="3" fill="none" strokeMiterlimit="10" data-color="color-2"/>
        </g>
    </svg>;

    static Delete = () => <i className="far fa-trash-alt"/>;
    static Share = () => <i className="fas fa-share-alt"/>;

    static Copy = () => <i className="far fa-copy"/>;

    render() {
        return null;
    }
}
