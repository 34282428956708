//@flow
import React, {useCallback, useContext, useEffect, useReducer} from 'react';
import {useSiteData} from "react-static";
import type {ImageInfo, SiteDataMeta} from "./static-api";
import {LangLightBox} from "./lib/ImageViewer";

type ImagesState = {
    visible: string|null;
    images: Array<string>;
}

function imagesReducer(state: ImagesState, action) {
    switch (action.type) {
        case "register":
            return { ...state, images: [ ...state.images, action.payload ] };
        case "unregister":
            return { ...state, images: state.images.filter(i => i!==action.payload)};
        case "show":
            return { ...state, visible: action.payload };
        case "hide":
            return { ...state, visible: null };
        default:
            throw new Error("Invalid action: "+action.type);
    }
}


const ImagesContext=React.createContext<function>(null);

const ImagesLightbox = ({ state, dispatch }: { state: ImagesState, dispatch: function }) => {
    if(!state.visible) return null;
    const src:string=state.visible;
    const images:Array<string>=state.images
    const index=images.indexOf(src);
    const next=images.length>1?images[(index+1)%images.length]:undefined;
    const prev=images.length>1?images[(index+images.length-1)%images.length]:undefined;

    return <LangLightBox
        mainSrc={src}
        nextSrc={next}
        prevSrc={prev}
        onCloseRequest={() => dispatch({ type: "hide" })}
        onMoveNextRequest={() => {
            if(next) dispatch({ type: "show", payload: next })
        }}
        onMovePrevRequest={() => {
            if(prev) dispatch({ type: "show", payload: prev })
        }}
        enableZoom={false}
        reactModalStyle={{
            overlay: {
                zIndex: 2000,
            },
            // content: {
            //     top: "5.5em",
            // }
        }}
    />
}

/**
 * Komponent do wyświetlania obrazków - kontekst, gdy obrazków jest więcej.
 */
const Images = ({ children }) => {
    const [ state, dispatch ] = useReducer(imagesReducer, { images: [], visible: null });
    // console.log("ImagesState ", state);
    return <>
        <ImagesContext.Provider value={dispatch}>{children}</ImagesContext.Provider>
        <ImagesLightbox state={state} dispatch={dispatch}/>
    </>
}

export default Images;

export const Image = ({ src, alt, className, ...props }: {
    src: string;
    alt: string;
    title?: string;
    className?: string;
}) => {
    const dispatch=useContext(ImagesContext);
    const { images } =useSiteData<SiteDataMeta>();
    const info: ImageInfo|undefined = images[src];

    useEffect(() => {
        if(!dispatch) return;   // gdy bez kontekstu - brak powiększenia
        // console.log("Register", src, info);
        dispatch({ type: "register", payload: src });
        return () => dispatch({ type: "unregister", payload: src });
    }, [ src ]);
    const handleClick=useCallback(() => {
        if(!dispatch) return;
        dispatch({ type: "show", payload: src });
    }, []);

    const cn=(className || "")+(dispatch?" c-zoom-in":"");

    if(info && dispatch && info.t===1 && info.w>512) {
        let thumb;
        if(src.endsWith(".jpg") || src.endsWith(".png")) thumb=src.substr(0, src.length-4)+".thumb.jpeg";
        else if(src.endsWith(".jpeg")) thumb=src.substr(0, src.length-5)+".thumb.jpeg";
        const height=Math.floor(512*(info.h/info.w));

        return <img
            loading="lazy"
            width={512} height={height}
            src={thumb} alt={alt} className={cn}
            onClick={handleClick} {...props}
        />
    }

    return <img
        loading="lazy"
        src={src} alt={alt}
        width={info?info.w:undefined} height={info?info.h:undefined}
        className={cn} onClick={handleClick} {...props}
    />;
}