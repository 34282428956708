//@flow
// Plik z obsługą dla serwisu user.com

export const USER_API_KEY=process.env.REACT_USER_API_KEY || "ojmYCa";

export type UEData = {
    apiKey: string;
    name?: string;
    user_id?: string;
    email?: string;
    gender?: number;
    status?: number;
    phone_number?: string;
    score?: number;
    company?: {
        name?: string;
        company_id?: string;
    },
    source_context?: {
        [string]: string
    }
};

export type PageHitCallbacks = {
    /** Triggered for every incoming message. Message object will be passed into triggered function. */
    onMessage?: (message: any) => void;
    /** Triggered when opening widget window. */
    onOpen?: () => void;
    /** Triggered when closing widget window. */
    onClose?: () => void;
    /** Can handle data sent through automation Send code module. Content of Send code module is passed as an argument to this method. */
    onPayloadReceived?: (message: any) => void;
}

/** event.name - Send event with your custom name and desired event attributes. Remember, create event attributes with specific data types before sending them over to User.com.<br/>
 * product_event - Using this method, you can trigger a product event on a specific action.<br/>
 * widget.action - By referring to the widget object, you can easily manage your widget state.<br/>
 * client.update - Using this method, you can update user attributes as well. However, it won't create a pageHit. Not every attribute can be changed with this method.<br/>
 **/
type UEUserEngage=(command: "widget.hide"|"widget.show"|"widget.open"|"widget.close"|"product_event"|"client.update"|string, params?: {}) => Promise<void>;

export interface UE {
    pageHit(data: UEData & PageHitCallbacks): Promise<void>;
    /** This function resets __ca__chat cookie, then creates a new one, which results in creating a new user. Parameter data is an object with the api key. Remember, it also resets a global cookie. You can use it to keep users privicy on the higher level. After user log out from your application, you can run this function and without loggin in, this user won’t be able to check conversations history on chat. */
    resetAuth({ apiKey: string }): Promise<void>;
    /** Destroy current widget instance and remove window from DOM. This method takes no arguments. After the current widget instance has been destroyed, you cannot refer to the ue methods. */
    destroy(): Promise<void>;
    // userengage: UEUserEngage;
}

/** Funkcja sprawdza, czy obiekt UE jest dostępny */
export function isUE(): boolean {
    return window && window.UE;
}

export function ueDo(action: (ue: UE) => Promise<void>): Promise<void> {
    if(!window || !window.UE) return Promise.resolve(); // gdy nie ma;
    const res=action(window.UE);   // jak jest, to akcja
    if(typeof(res)==='object' && typeof(res.then)==='function') return res;
    return Promise.resolve(res);
}

/** Funkcja wysyła zdarzenie do user.com o zadanej nazwie tj. event.name. */
export function ueEvent(name: string): Promise<void> {
    return ueDo(ue => window.userengage("event."+name));
}

/** Opakowanie na UE.pageHit */
export function uePageHit(data: UEData): Promise<void> {
    if(!data.apiKey) data.apiKey=USER_API_KEY;
    return ueDo(ue => ue.pageHit(data));
}

export function ueChat() {
    return ueDo(ue => window.userengage("widget.show"));
}