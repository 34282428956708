//@flow
// Plik z kodem do obsługi rzeczy wielojęzykowych w oparciu o Recoil

import type {LanguageData} from "../LangType";
import {atom, MutableSnapshot, selector, useRecoilValue} from "recoil";

/** Atom z aktualnym językiem ustawionym dla aplikacji */
export const langAtom=atom<string>({
    key: 'lang',
    default: 'pl',
});

export const langModulesAtom=atom<string>({
    key: 'langModules',
    default: '',
});


export function initializeLanguage(ss: MutableSnapshot, modules: string, path?: string): MutableSnapshot {
    ss.set(langModulesAtom, modules);

    if(!path) path=window.location.pathname;
    let lang='pl';
    if(path.startsWith("/pl/") || path==="/pl") lang='pl';
    else if(path.startsWith("/en/") || path==="/en") lang="en";

    console.log("Detect for: ", path, '=>', lang);
    ss.set(langAtom, lang);

    return ss;
}

export const msgsAtom=process.env.REACT_APP_TYPE==='dynamic'?selector<LanguageData>({
    key: 'msgs',
    get: ({ get }) => {
        const lang=get(langAtom);
        const mod=get(langModulesAtom);
        console.log("Lang: ", lang, "Mod: ", mod);
        const url=`/lang/${lang}/${mod}.json`;
        console.log("KeySelector for URL: ", url);
        return fetch(url, { method: "GET", }).then(res => res.json());
    }
}):atom<LanguageData>({
   key: 'msgs',
   default: null
});

/** Zwraca klucze językowe dla aktualnego języka */
export function useMsgs(): LanguageData {
    return useRecoilValue(msgsAtom);
}

/** Zwraca aktualnie wybrany język */
export function useLang(): string {
    return useRecoilValue(langAtom);
}

/**
 * Formatowanie zgodne, uproszczone do tego z Javowym MessageFormatter.
 * @param format
 * @param args
 */
export function formatString(format: string, ...args): string {
    if(args===undefined || args===null || args.length===0) return format;
    if(format===null || format===undefined) return "";
    let str=format;
    for(let i=0;i<args.length;++i) str=str.replace('{'+i+'}', String(args[i]));
    return str;
}

export function getLangString(msgs: LanguageData, key: any): string {
    if(typeof(key)!=='string' || key.length<2) return key;
    if(key.startsWith("commons.")) return msgs.commons[key.substring(8)];
    else if(key.startsWith("account.")) return msgs.account[key.substring(8)];
    else if(key.startsWith("admin.")) return msgs.admin[key.substring(6)];
    else if(key.startsWith("draw.")) return msgs.draw[key.substring(5)];
    return key;
}

export function useMsgsGetter(): (key: any) => string {
    const msgs=useMsgs();
    return (key: any) => getLangString(msgs, key);
}