//@flow
import React from 'react';
import {Button, ButtonProps, Card, Form, OverlayTrigger, Tooltip} from 'react-bootstrap';
import type {AccountType} from "../api";
import {Icon} from "./Icon";
import {formatString, useMsgs} from "./Language";

/** Komponent do karty cennika */
const PacketCard = ({ children }) => {
    return <Card className="price-card ml-md-auto">
        <Card.Body className="text-center">
            {children}
        </Card.Body>
    </Card>
}

const PacketTitle = ({ children }) => {
    return <Card.Title>{children}</Card.Title>;
}

const PacketSubtitle = ({ children }) => {
    return <Card.Text><b>{children}</b></Card.Text>;
}

const PacketItem = ({ id, disabled, hint, children }: { id: string, disabled?: boolean, hint?: string|React$Node }) => {
    if(hint) {
        const tooltip=<Tooltip id={id+"_tt"}>{hint}</Tooltip>
        return <OverlayTrigger placement="top" delay={{ show: 250, hide: 200 }} overlay={tooltip}>
            <Card.Text className="price-item">
                {disabled?<s>{children}</s>:children} <sup><Icon.Help/></sup>
            </Card.Text>
        </OverlayTrigger>
    } else return <Card.Text className="price-item">{disabled?<s>{children}</s>:children}</Card.Text>;
}

export const PacketPrice = ({ children }) => {
    return <h5 className="price">{children}</h5>
}

export const PacketFooter = ({ children, ...props }) => {
    return <p {...props}>{!children?<>&nbsp;</>:children}</p>
}

export const PacketAction = ({ children, history, href, ...props }: ButtonProps ) => {
    if(history) {
        return <Button block href={process.env.PUBLIC_URL+href} {...props} onClick={(e) => {
            e.preventDefault();
            history.push({
                pathname: href,
            });
        }}>{children}</Button>;
    }
    return <Button block href={href} {...props}>{children}</Button>;
}

/** Rodzaje płatności - dla pakietów */
export type PaymentMode="month"|"year"|"users";

/** Dostępne produkty do kupienia */
export type PacketProduct="free_10_exports"|"basic_month"|"basic_year"|"company_month"|"company_year"|"promo"|"company_users";


export const PriceMode = ({value, onChange, usersOnly }: {
    value: PaymentMode, onChange: (e: SyntheticEvent) => void,
    /** Czy ma być dostępny tryb wyboru bez pakietu (dokupienie użytkowników) */
    usersOnly?: boolean
}) => {
    const msgs=useMsgs();
    return <Form inline><Form.Control as="select" size="sm" custom value={value} onChange={onChange}>
        {usersOnly && <option value="users">{msgs.account.packetPeriodUsersOnly}</option>}
        <option value="month">{msgs.commons.packetPeriodMonthly}</option>
        <option value="year">{msgs.commons.packetPeriodYearly}</option>
    </Form.Control></Form>
}


const Packet=({ type, children }: { type: AccountType }) => {
    const msgs=useMsgs();
    const id=type+"_";
    return <PacketCard>
        <PacketTitle>{msgs.commons['packetTitle'+type]}</PacketTitle>
        <PacketSubtitle>{msgs.commons['packetSubtitle1'+type]}<br/>{msgs.commons['packetSubtitle2'+type]}</PacketSubtitle>
        <br/>
        <PacketItem id={id+"projects"}
                    hint={msgs.commons.packetItemUnlimitedProjectsHint}
        >{msgs.commons.packetItemUnlimitedProjects}</PacketItem>
        <PacketItem id={id+"overlay"}
                    hint={msgs.commons.packetItemOverlayHint}
        >{msgs.commons.packetItemOverlay}</PacketItem>
        <PacketItem id={id+"furniture"}
                    hint={msgs.commons.packetItemFurnitureHint}
        >{msgs.commons.packetItemFurniture}</PacketItem>
        <PacketItem id={id+"templates"}
                    hint={msgs.commons.packetItemTemplatesHint}
        >{msgs.commons.packetItemTemplates}</PacketItem>
        <PacketItem disabled={type==="Free"} id={id+"exportTemplates"}
                    hint={msgs.commons.packetItemExportTemplatesHint}
        >{msgs.commons.packetItemExportTemplates}</PacketItem>
        <PacketItem id={id+"exportsCount"}
                    hint={type==="Free"?msgs.commons.packetItemBuyExportsHint:null}
        >{type==="Free"?msgs.commons.packetItemBuyExports:msgs.commons.packetItemUnlimitedExports}</PacketItem>
        <PacketItem disabled={type==="Free"} id={id+"photo"}
                    hint={msgs.commons.packetItemPhotoHint}
        >{msgs.commons.packetItemPhoto}</PacketItem>
        <PacketItem disabled={type!=="Company"} id={id+"logo"}
                    hint={msgs.commons.packetItemLogoHint}
        >{msgs.commons.packetItemLogo}</PacketItem>
        <PacketItem disabled={type!=="Company"} id={id+"accounts"}
                    hint={msgs.commons.packetItemAccountsHint}
        >{type==="Company"?formatString(msgs.commons.packetItemAccountsAmount, 5):msgs.commons.packetItemAccounts}</PacketItem>
        {children}
    </PacketCard>
}

export default Packet;
